import { createApi } from '@reduxjs/toolkit/dist/query/react';
import configs from '../../app/configs';
import {
  IGetAllRbicResponse,
  IGetRbicHistoryById,
  IRBICDropdownResponse,
  IRBICResultFormPayload,
  IRBICSaveResponse,
} from '../../components/RBI-C/RBIC.types';
import { IApiRbicUpdatePayload } from '../../features/RBIC/history/types';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import { IGetAllRbicQueryParams } from './rbic-api.types';

export const rbicApiSlice = createApi({
  reducerPath: 'rbicApi',
  baseQuery: rtkFetchWrapper(),
  tagTypes: ['Rbic'],
  endpoints(builder) {
    return {
      getAllRbic: builder.query<IGetAllRbicResponse, IGetAllRbicQueryParams | void>({
        query(queryParams) {
          const options: any = { url: configs?.endpoints.rbic };

          if (queryParams) {
            options.params = queryParams;
          }

          return options;
        },
        providesTags: ['Rbic'],
      }),
      getRbicById: builder.query<IGetRbicHistoryById, string>({
        query(rbicId: string) {
          return { url: `${configs?.endpoints?.rbic}/${rbicId}` };
        },
      }),
      getRbicDropdown: builder.query<IRBICDropdownResponse, void>({ query: () => ({ url: configs?.endpoints.rbicDropdown }) }),
      saveRbicData: builder.mutation<IRBICSaveResponse, IRBICResultFormPayload>({
        query: (payload: IRBICResultFormPayload) => ({
          url: configs?.endpoints.rbic,
          method: 'POST',
          body: payload,
        }),
      }),
      saveRbicDataBulk: builder.mutation<any, any>({
        query: (payload: IRBICResultFormPayload[]) => ({
          url: configs?.endpoints.rbicBulk,
          method: 'POST',
          body: payload,
        }),
      }),
      updateRbicData: builder.mutation({
        query: (payload: IApiRbicUpdatePayload) => ({
          url: configs?.endpoints.rbic,
          method: 'PATCH',
          body: payload,
        }),
      }),
    };
  },
});

export const {
  useSaveRbicDataMutation,
  useGetAllRbicQuery,
  useGetRbicDropdownQuery,
  useGetRbicByIdQuery,
  useSaveRbicDataBulkMutation,
  useUpdateRbicDataMutation,
} = rbicApiSlice;
