import {
  AccountInfo, IPublicClientApplication, PublicClientApplication,
} from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';
import { loginRequest, msalConfig } from '../../app/authConfig';

interface DecodedToken {
  upn: string;
}

export const getEmailFromToken = (token: string): string => {
  const decoded = jwtDecode<DecodedToken>(token);
  return decoded?.upn ?? '';
};

export const setAccessToken = (token: string) => {
  if (typeof Storage === 'undefined') {
    throw new Error('No valid storage type found');
  }
  console.log('In: setToken');
  localStorage.setItem('token', token);
};

export function removeAccessToken() {
  localStorage.removeItem('token');
}

export const getAccessToken = () => localStorage.getItem('token');

// Let's assume,
// CurTime = 2:36
// ExpTime = 2:50
// isExpired ==> true | false | null ==> expired|not-expired|not-logged-in;
export const verifyTokenExpired = (bufferTime = 0) => {
  let isExpired = null;

  // if (!isAuthenticated) {
  const instance = new PublicClientApplication(msalConfig);
  const accounts = instance.getAllAccounts();
  if (accounts.length <= 0) return isExpired;
  // isAuthenticated = true;
  // }

  // console.log('isAuthenticated:', isAuthenticated);
  // if (!isAuthenticated) return isExpired;

  const msalToken = getAccessToken();
  // console.log('msalToken:', msalToken);
  // may be still in progress to setup the token for the first time;
  if (!msalToken) return true;

  try {
    // Decode the token to get the payload
    const decodedToken: { [key: string]: any; } = jwtDecode(msalToken);
    if (!(decodedToken?.exp)) return isExpired;

    // Get the expiration time from the payload
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
    // const notB4ClaimTime = (decodedToken.nbf ?? 0) * 1000; // -->Debug only!

    // Get the current timestamp
    const currentTimestamp = Date.now();

    // console.log('Token:', new Date(expirationTime), new Date(currentTimestamp), (expirationTime - currentTimestamp) / 1000, decodedToken.exp);

    // console.log(
    //   bufferTime,
    //   ` notB4ClaimTime : ${notB4ClaimTime}`,
    //   (bufferTime >= (expirationTime - currentTimestamp) / 1000),
    //   ' || ',
    //   notB4ClaimTime + 3 * 60 * 1000,
    //   ' curr ',
    //   notB4ClaimTime + 3 * 60 * 1000 <= currentTimestamp,
    // );

    if (
      expirationTime <= currentTimestamp
      || (bufferTime && (bufferTime >= ((expirationTime - currentTimestamp) / 1000)))
      // || (notB4ClaimTime + 7 * 60 * 1000) <= currentTimestamp // -->Debug only!
    ) {
      console.log('Token has expired');
      // removeAccessToken();
      isExpired = true;
    } else {
      console.log('Token has not expired');
      isExpired = false;
    }
  } catch (error: any) {
    console.error('Error decoding token:', error.message);
  }
  return isExpired;
};

export const regenerateToken = async (
  instance?: IPublicClientApplication,
  activeAccount?: AccountInfo,
): Promise<string | null> => {
  try {
    if (!instance) {
      instance = new PublicClientApplication(msalConfig);
    }
    if (!activeAccount) {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        activeAccount = accounts?.[0];
      }
    }
    const refreshedResponse = await instance.acquireTokenSilent({
      ...loginRequest,
      forceRefresh: true,
      account: activeAccount,
    });
    console.log('New Token:', [refreshedResponse.accessToken,
      instance,
      activeAccount]);
    setAccessToken(refreshedResponse.accessToken);
    return refreshedResponse.accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};

export const getLocalStorageData = (key: string) => {
  if (typeof Storage === 'undefined') return null;
  return localStorage.getItem(key);
};

export const setLocalStorageData = (key: string, value: any) => {
  if (typeof Storage === 'undefined') {
    throw new Error('No valid storage type found');
  }
  localStorage.setItem(key, JSON.stringify(value));
};
