// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = { loaderOpen: false };

const loaderSlice = createSlice({
  name: 'commonLoader', // slice name
  initialState, // initial state
  reducers: { // reducers
    setCommonLoader(state, action: PayloadAction<boolean>) {
      state.loaderOpen = action.payload;
    },
  },
});

export const { setCommonLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
