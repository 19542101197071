// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { USER_ROLES_FORM_INPUTS } from '../../form-constants/UserRoles';
import { UserRolesInterface } from './types';

const initialState: UserRolesInterface = {
  [USER_ROLES_FORM_INPUTS.ROLE_NAME]: '',
  [USER_ROLES_FORM_INPUTS.ROLE_DESCRIPTION]: '',
  [USER_ROLES_FORM_INPUTS.ROLE_CHECKED_KEYS]: [],
  [USER_ROLES_FORM_INPUTS.ROLE_EXPANDED_KEYS]: [],
  [USER_ROLES_FORM_INPUTS.ROLE_ID]: '',
};

const userRolesSlice = createSlice({
  name: 'userRole', // slice name
  initialState, // initial state
  reducers: { // reducers
    setUserRoleValue(state: UserRolesInterface, action: PayloadAction<UserRolesInterface>) {
      const { payload } = action;
      state[USER_ROLES_FORM_INPUTS.ROLE_NAME] = payload[USER_ROLES_FORM_INPUTS.ROLE_NAME];
      state[USER_ROLES_FORM_INPUTS.ROLE_DESCRIPTION] = payload[USER_ROLES_FORM_INPUTS.ROLE_DESCRIPTION];
      state[USER_ROLES_FORM_INPUTS.ROLE_CHECKED_KEYS] = payload[USER_ROLES_FORM_INPUTS.ROLE_CHECKED_KEYS];
      state[USER_ROLES_FORM_INPUTS.ROLE_EXPANDED_KEYS] = payload[USER_ROLES_FORM_INPUTS.ROLE_EXPANDED_KEYS];
      state[USER_ROLES_FORM_INPUTS.ROLE_ID] = payload[USER_ROLES_FORM_INPUTS.ROLE_ID];
    },
  },
});

export const { setUserRoleValue } = userRolesSlice.actions;
export default userRolesSlice.reducer;
