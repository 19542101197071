import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRBICSaveResponse } from '../../../components/RBI-C/RBIC.types';

const initialState: Partial<IRBICSaveResponse> = {};

const rbicResultSlice = createSlice({
  name: 'rbicResultSlice',
  initialState,
  reducers: { // reducers
    updateRbicResult(state, action: PayloadAction<Partial<IRBICSaveResponse>>) {
      state.data = action.payload.data;
      state.message = action.payload.message;
      state.success = action.payload.success;
    },
  },
});

export const { updateRbicResult } = rbicResultSlice.actions;

export default rbicResultSlice.reducer;
