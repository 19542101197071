import React, { Suspense, useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { useAppDispatch } from './app/hooks';
import { useFetchUserPermissionsQuery, useFetchUserQuery } from './features/users/api';
import { getAccessToken, verifyTokenExpired } from './utils/auth/auth.util';
import { setPermissionsData } from './features/permissions/permissionsAlice';
import {
  setCurrentUserCompany, setCurrentUserEmail, setCurrentUserId,
} from './features/submission';
import ServerError from './components/auth/ServerError';
import Forbidden from './components/auth/Forbidden';
import PnSpinner from './components/pn-spinner/PnSpinner';
import LoginPage from './components/auth/LoginPage';

// import handleLogout from './utils/auth/handleLogout';

const Layout = React.lazy(() => import('./components/layout/Layout'));

function App() {
  const {
    inProgress,
    // instance,
    accounts,
  } = useMsal();

  // console.log('>>>getTokenCache():', instance.getTokenCache());
  const activeAccount = accounts?.[0];
  // const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const isExpired = verifyTokenExpired();
  const dispatch = useAppDispatch();

  console.log('>>>location.pathname4:', isExpired);
  // if (isExpired) handleLogout('redirect', instance, activeAccount);

  const {
    data: user,
    isLoading: userLoading,
    isSuccess,
    isError,
    isFetching: userFetching,
  } = useFetchUserQuery(activeAccount?.username, { skip: getAccessToken() === null || !activeAccount });

  const {
    data: userPermissions,
    isLoading: userPermissionsLoading,
    isFetching: userPermissionsFetching,
  } = useFetchUserPermissionsQuery(activeAccount?.username, { skip: getAccessToken() === null || !activeAccount });

  useEffect(() => {
    // set user permissions
    if (isAuthenticated
      // && !isTokenExpired
      && activeAccount
      && (userPermissions
        && Array.isArray(userPermissions?.data)
        && userPermissions.data.length > 0
      )
    ) {
      localStorage.setItem('userPermissions', JSON.stringify(userPermissions?.data || []));
      dispatch(setPermissionsData({ userPermissions: userPermissions?.data }));
    }
  }, [
    isAuthenticated,
    // isTokenExpired,
    userPermissions,
    dispatch,
    activeAccount,
  ]);

  useEffect(() => {
    dispatch(setCurrentUserEmail(user?.data?.email));
    dispatch(setCurrentUserId(String(user?.data?.id)));
    dispatch(setCurrentUserCompany(user?.data?.Company?.companyName));
  }, [
    user, dispatch,
  ]);

  if (userLoading
    || userFetching
    || userPermissionsLoading
    || userPermissionsFetching
    || inProgress !== InteractionStatus.None
  ) return <PnSpinner />;

  if ((isAuthenticated && isSuccess && !user?.data) || user?.data?.status === 'inactive') return (<Forbidden />);
  if (!isAuthenticated || isExpired) return (<Router><LoginPage /></Router>);
  if (isAuthenticated && isError && !user?.data) return <ServerError />;

  return (
    <Router>
      <Suspense fallback={<PnSpinner />}>
        <Layout />
      </Suspense>
    </Router>
  );
}

export default App;
