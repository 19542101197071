import { useMsal } from '@azure/msal-react';
import { Button, Result } from 'antd';
import logout from '../../utils/auth/handleLogout';
import '../../styles/antdLibStyles.less';

function ServerError() {
  const { instance, accounts } = useMsal();

  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong!"
      extra={<Button type="primary" onClick={() => logout('redirect', instance, accounts?.[0])}>Logout</Button>}
    />
  );
}

export default ServerError;
