import { createApi } from '@reduxjs/toolkit/query/react';
import { FRONTEND_BASE_URL } from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';

export interface SubmissionAndApproval {
  submitterName?: string;
  submitterEmail: string;
  approverName?: string;
  approverEmail: string;
  moduleId: string;
  requestType: string;
  status: string;
  reviewRemark?: string,
  approvalRemark?: string,
  reviewerName: string;
  reviewerEmail: string;
  submittedAt?: string;
  reviewedAt?: string;
  approvedAt?: string;
  emailTemplate: string;
  itemId?: string;
  parentId?: string;
}

export interface IFetchPendingQueryParams {
  userEmail?: string,
  parentId?: string,
  requestId?: string,
  requestType?: string,
  itemId?: string,
  companyName?: string,
}

export interface SubmissionAndApprovalRequest extends SubmissionAndApproval {
  id?: string;
  FR_BASE_URL?: string;
  moduleURL: string;
  isApproval?: boolean;
}

export const submissionAndApprovalApiSlice = createApi({
  reducerPath: 'submissionAndApprovalApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      fetchSubmissions: builder.query({ query: () => '/submission-for-review-management/submissions' }),
      fetchReviews: builder.query({ query: () => '/review-for-approval-management/reviews' }),
      fetchReviewsPending: builder.query<any, string>({
        query: (userEmail: string) => ({
          url: `/review-for-approval-management/pending-reviews/${userEmail.split('@')[0]}`,
          method: 'GET',
        }),
      }),
      fetchReviewsById: builder.query<any, string>({ query: (id: string) => `/submission-approval-management/submission-approvals/${id}` }),
      fetchPendingApprovalListByUser: builder.query<any, IFetchPendingQueryParams | void>({
        query(queryParams) {
          const options: any = { url: '/submission-approval-management/pending' };
          if (queryParams) {
            queryParams = { ...queryParams, userEmail: queryParams?.userEmail?.split('@')[0] };
            options.params = queryParams;
          }
          return options;
        },
      }),
      fetchPendingApprovalListDropdownByUser: builder.query<any, string>(
        { query: (userEmail: string) => `/submission-approval-management/pending/${userEmail.split('@')[0]}` },
      ),
      fetchReviewers: builder.query({ query: () => 'user-management/users/role/role-4' }),
      fetchApprovers: builder.query({ query: () => 'user-management/users/role/role-3' }),
      fetchApprovalReviewHistoryId:
      builder.query<any, string>({ query: (repairId: string) => `/submission-approval-management/submission-approvals/history/${repairId}` }),
      createSubmissionForReview: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/submission-for-review-management/submissions',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      createReviewForApproval: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/review-for-approval-management/reviews',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      rejectSubmissionFromReview: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/reject-submission-management/reject-from-review',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      approveSubmission: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/approve-submission-management/approve-submission',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      rejectSubmissionFromApproval: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/reject-submission-management/reject-from-approval',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      reSubmissionForApproval: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/submission-for-review-management/re-submissions',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      cancelFromApproval: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/submission-approval-management/cancel-approvals',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
    };
  },
});

export const {
  useCreateSubmissionForReviewMutation, useCreateReviewForApprovalMutation, useFetchReviewsQuery, useFetchSubmissionsQuery, useFetchReviewsPendingQuery,
  useFetchReviewsByIdQuery, useFetchPendingApprovalListByUserQuery, useFetchPendingApprovalListDropdownByUserQuery, useFetchReviewersQuery, useFetchApproversQuery,
  useFetchApprovalReviewHistoryIdQuery, useApproveSubmissionMutation, useRejectSubmissionFromReviewMutation, useRejectSubmissionFromApprovalMutation,
  useReSubmissionForApprovalMutation, useCancelFromApprovalMutation,
} = submissionAndApprovalApiSlice;
