import { createApi } from '@reduxjs/toolkit/dist/query/react';
import configs from '../../../app/configs';
import {
  IIMPCombination2Response, IIMPCombinationResponse, IIMPPayload,
} from '../../../components/RBI-C/RBIC.types';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';

export const impApi = createApi({
  reducerPath: 'impApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      saveImpData: builder.mutation({
        query: (payload: IIMPPayload) => ({
          url: configs.endpoints.imp,
          method: 'POST',
          body: payload,
        }),
      }),
      getAllIMPCombination: builder.query<IIMPCombinationResponse, void>({
        query() {
          return { url: `${configs?.endpoints?.impcombination}` };
        },
      }),
      getAllIMPCombinationTwo: builder.query<IIMPCombination2Response, void>({
        query() {
          return { url: `${configs?.endpoints?.impcombination2}` };
        },
      }),
    };
  },
});

export const {
  useSaveImpDataMutation,
  useGetAllIMPCombinationQuery,
  useGetAllIMPCombinationTwoQuery,
} = impApi;
