// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = { substrateInformationId: -1 };

const substrateInformationResponseSlice = createSlice({
  name: 'substrateInformationResponse', // slice name
  initialState, // initial state
  reducers: { // reducers
    setsubstrateInformationResponse(state, action: PayloadAction<number>) {
      state.substrateInformationId = action.payload;
    },
  },
});

export const { setsubstrateInformationResponse } = substrateInformationResponseSlice.actions;
export default substrateInformationResponseSlice.reducer;
