export enum DEFECT_INFORMATION_FORM_INPUTS {
  TYPE_OF_DEFECT = 'typeOfDefect',
  DIAMETER_OF_THROUGH_WALL_DEFECT = 'diameterThroughWall',
  DIAMETER_OF_THROUGH_WALL_DEFECT_UNIT = 'diameterThroughWall_unit',
  AXIAL_LENGTH_OF_DEFECT = 'axialLength',
  AXIAL_LENGTH_OF_DEFECT_UNIT = 'axialLength_unit',
  MAWP_PSAFE = 'psafe',
  MAWP_PSAFE_UNIT = 'psafe_unit',
  SHAPE_OF_DEFECT = 'shapeOfDefect',
  MINIMUM_REMAINING_SUBSTRATE_WALL_THICKNESS = 'substrateWallThickness',
  MINIMUM_REMAINING_SUBSTRATE_WALL_THICKNESS_UNIT = 'substrateWallThickness_unit',
  AXIAL_CIRCUMFERENTIAL_SLOT = 'axialCircumferentialSlot',
  AXIAL_CIRCUMFERENTIAL_SLOT_UNIT = 'axialCircumferentialSlot_unit',
}
