export enum DESIGN_REVALIDATION_RECLASSIFICATION_FORM_INPUTS {
  CHANGES_IN_CURRENT_DEFECT = 'changesInCurrentDefect',
  VISUAL_DEFECT_OR_DAMAGE = 'visualDefectOrDamage',
  RECLASSIFICATION_STATUS = 'reClassificationStatus',
}

export interface DesignRevalidationReclassificationInterface {
  [DESIGN_REVALIDATION_RECLASSIFICATION_FORM_INPUTS.CHANGES_IN_CURRENT_DEFECT]: string;
  [DESIGN_REVALIDATION_RECLASSIFICATION_FORM_INPUTS.VISUAL_DEFECT_OR_DAMAGE]: string;
  [DESIGN_REVALIDATION_RECLASSIFICATION_FORM_INPUTS.RECLASSIFICATION_STATUS]: string;
}

export type DesignRevalidationReclassificationKeys = keyof DesignRevalidationReclassificationInterface;

export interface PayloadActionType {
  type: DesignRevalidationReclassificationKeys;
  payload: string;
}
