import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

const handleLogout = async (
  logoutType: 'popup' | 'redirect',
  instance: IPublicClientApplication,
  activeAccount: AccountInfo,
) => {
  console.log('>>>Clear localStorage');
  localStorage.clear();
  try {
    if (logoutType === 'popup') {
      await instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else if (logoutType === 'redirect') {
      // The account's ID Token must contain the login_hint optional claim to avoid the account picker
      await instance.logoutRedirect({
        account: activeAccount,
        postLogoutRedirectUri: '/',
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export default handleLogout;
