// DUCKS pattern
import { createApi } from '@reduxjs/toolkit/query/react';
import configs from '../../../app/configs';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';
import { DesignOutputInterface, IDesignOutpuConsideration } from './types';

export const designOutputApi = createApi({
  reducerPath: 'designOutputApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addDesignOutputApi: builder.mutation({
        query: (payload: DesignOutputInterface) => ({
          url: configs?.endpoints?.designOutput,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const designOutputConsiderationApi = createApi({
  reducerPath: 'designOutputConsiderationApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addDOC: builder.mutation({
        query: (payload: IDesignOutpuConsideration) => ({
          url: '/doc-management/design-output-consideration',
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { useAddDesignOutputApiMutation } = designOutputApi;
export const { useAddDOCMutation } = designOutputConsiderationApi;
