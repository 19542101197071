export enum LAMINATE_INFORMATION_FORM_INPUTS {
  COMPOSITE_REPAIR_BRAND = 'compositeRepairBrand',
  PRODUCT_NAME = 'productName',
  MANUFACTURER = 'manufacturer',
  LAMINATE_WIDTH = 'laminateWidth',
  LAMINATE_WIDTH_UNIT = 'laminateWidth_unit',
  TENSILE_MODULUS_OF_LAMINATE_AXIAL = 'tensileModulusOfLaminateAxial',
  TENSILE_MODULUS_OF_LAMINATE_CIRCUMFERENTIAL = 'tensileModulusOfLaminateCircumferential',
  COMBINED_TENSILE_MODULUS = 'combinedTensileModulus',
  SHEAR_MODULUS_OF_LAMINATE = 'shearModulusOfLaminate',
  THERMAL_EXPANSION_COEFFICIENT_AXIAL = 'thermalExpansionCoefficientAxial',
  THERMAL_EXPANSION_COEFFICIENT_CIRCUMFERENTIAL = 'thermalExpansionCoefficientCircumferential',
  GLASS_TRANSITION_TEMPERATURE = 'glassTransitionTemperature',
  THICKNESS_OF_AN_INDIVIDUAL_LAYER_OF_LAMINATE = 'thicknessOfAnIndividualLayerOfLaminate',
  THICKNESS_OF_AN_INDIVIDUAL_LAYER_OF_LAMINATE_UNIT = 'thicknessOfAnIndividualLayerOfLaminate_unit',
  CONFIDENCE_LIMIT_OF_ENERGY_RELEASE_RATE = 'confidenceLimitOfEnergyReleaseRate',
  LAP_SHEAR_STRENGTH_BETWEEN_LAMINATE_AND_SUBSTRATE = 'lapShearStrengthBetweenLaminateAndSubstrate',
  POISSONS_RATIO_OF_LAMINATE = 'poissonsRatioOfLaminate',
  ALLOWABLE_LAMINATE_AXIAL_STRAIN = 'allowableLaminateAxialStrain',
  ALLOWABLE_LAMINATE_CIRCUMFERENTIAL = 'allowableLaminateCircumferentialStrain'
}
