// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  assetDataUniqueId: '',
  assetDataId: -1,
  repairDesignDataId: -1,
  repairDesignDataCode: '',
  assetDataIdForRepairDesignInput: -1,
  assetDataCompanyForRepairDesignInput: -1,
  assetDataLocationForRepairDesignInput: '',
  assetDataEqTagForRepairDesignInput: '',
  assetDataEqTypeForRepairDesignInput: -1,
  assetDataServiceForRepairDesignInput: '',
  assetDataUniqueIdForRepairDesignInput: '',
  assetDataCOFForRepairDesignInput: -1,
  assetDataPipeGradeForRepairDesignInput: '',
  assetDataMaterialGradeForRepairDesignInput: '',
  assetDataDesignTempForRepairDesignInput: -1,
  compositeRepairIdForRepairDesignInput: -1,
  compositeRepairCodeForRepairDesignInput: '',
};

const assetDataSlice = createSlice({
  name: 'assetDataSlice', // slice name
  initialState, // initial state
  reducers: { // reducers
    setAssetId(state, action: PayloadAction<{ uniqueId: string, id: number }>) {
      state.assetDataUniqueId = action.payload.uniqueId;
      state.assetDataId = action.payload.id;
      state.assetDataIdForRepairDesignInput = action.payload.id;
      state.assetDataUniqueIdForRepairDesignInput = action.payload.uniqueId;
    },
    setAssetDataUniqueId(state, action: PayloadAction<string>) {
      state.assetDataUniqueId = action.payload;
    },
    setAssetDataId(state, action: PayloadAction<number>) {
      state.assetDataId = action.payload;
    },
    setAssetDataInfoForRepairDesignInput(state, action: PayloadAction<{
      id: number,
      uniqueId: string,
      company: number,
      location: string,
      eqTag: string,
      eqType: number,
      service: string,
      cof?: number;
      materialGrade?: string;
      pipeGrade?: string;
      designTemp?: number;
    }>) {
      state.assetDataIdForRepairDesignInput = action.payload.id;
      state.assetDataUniqueIdForRepairDesignInput = action.payload.uniqueId;
      state.assetDataCompanyForRepairDesignInput = action.payload.company;
      state.assetDataLocationForRepairDesignInput = action.payload.location;
      state.assetDataEqTagForRepairDesignInput = action.payload.eqTag;
      state.assetDataEqTypeForRepairDesignInput = action.payload.eqType;
      state.assetDataServiceForRepairDesignInput = action.payload.service;

      if (action.payload.cof) {
        state.assetDataCOFForRepairDesignInput = action.payload.cof;
      }

      if (action.payload.materialGrade) {
        state.assetDataMaterialGradeForRepairDesignInput = action.payload.materialGrade;
      }

      if (action.payload.pipeGrade) {
        state.assetDataPipeGradeForRepairDesignInput = action.payload.pipeGrade;
      }

      if (action.payload.designTemp) {
        state.assetDataDesignTempForRepairDesignInput = action.payload.designTemp;
      }
    },
    setCompositeRepairIdForRepairDesignInput(state, action: PayloadAction<{ id: number, code: string }>) {
      state.compositeRepairIdForRepairDesignInput = action.payload.id;
      state.compositeRepairCodeForRepairDesignInput = action.payload.code;
    },
    setRepairDesignDataId(state, action: PayloadAction<{ id: number, code: string }>) {
      state.repairDesignDataId = action.payload.id;
      state.repairDesignDataCode = action.payload.code;
    },
  },
});

export const {
  setAssetId,
  setAssetDataUniqueId,
  setAssetDataId,
  setRepairDesignDataId,
  setAssetDataInfoForRepairDesignInput,
  setCompositeRepairIdForRepairDesignInput,
} = assetDataSlice.actions;
export default assetDataSlice.reducer;
