// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit';

interface ModalState {
  open: boolean;
}

const initialState: ModalState = { open: true };

const modalSlice = createSlice({
  name: 'lifetime-extension-modal', // slice name
  initialState, // initial state
  reducers: { // reducers
    setModal(state, action) {
      state.open = action.payload;
    },
  },
});

export const { setModal } = modalSlice.actions;
export default modalSlice.reducer;
