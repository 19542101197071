import { createApi } from '@reduxjs/toolkit/query/react';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import configs from '../../app/configs';
import {
  IApiRepairDesignByIDResponse,
  IApiRepairDesignListResponse,
  RepairDesignDropDownResponse,
  RepairDesignQueryParams,
} from './types';
import { IRepairDesignHistoryRequest } from '../../features/RepairDesign/history/type';

export const repairDesignAPI = createApi({
  reducerPath: 'repairDesignAPI',
  baseQuery: rtkFetchWrapper(),
  tagTypes: ['repair-design'],
  endpoints(build) {
    return {
      getDropdownData: build.query<RepairDesignDropDownResponse, void>({ query: () => ({ url: configs?.endpoints?.repairDesignDropdown }) }),
      getList: build.query<IApiRepairDesignListResponse, RepairDesignQueryParams>({
        query(queryParams) {
          const options: any = { url: configs?.endpoints?.repairDesigns };

          if (queryParams) {
            options.params = queryParams;
          }

          return options;
        },
      }),
      getRepairDesignById: build.query<IApiRepairDesignByIDResponse, string>({ query: (rdmID) => ({ url: `${configs?.endpoints?.repairDesigns}/${rdmID}` }) }),
      updateRepairDesignISO: build.mutation({
        query: (payload: IRepairDesignHistoryRequest) => ({
          url: configs?.endpoints?.repairDesigns,
          method: 'PATCH',
          body: payload,
        }),
      }),
    };
  },
});

export const {
  useGetDropdownDataQuery,
  useGetListQuery,
  useGetRepairDesignByIdQuery,
  useUpdateRepairDesignISOMutation,
} = repairDesignAPI;
