import { UserRolesInterface } from '../../features/user-roles/types';

export enum USER_ROLES_FORM_INPUTS {
  ROLE_NAME = 'roleName',
  ROLE_DESCRIPTION = 'roleDescription',
  ROLE_CHECKED_KEYS = 'checkedKeys',
  ROLE_EXPANDED_KEYS = 'expandedKeys',
  ROLE_ID = 'roleId',
}

export type USER_ROLE_UPDATE_FORM = Pick<UserRolesInterface, USER_ROLES_FORM_INPUTS.ROLE_NAME | USER_ROLES_FORM_INPUTS.ROLE_DESCRIPTION>
