import { createApi } from '@reduxjs/toolkit/dist/query/react';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';

export const applicationHistoryApiSlice = createApi({
  reducerPath: 'applicationHistoryApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      getApplicationHistory: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.retrieveApplicationHistory,
          method: 'POST',
          body: payload,
        }),
      }),
      getApplicationHistoryById: builder.query({
        query: (logId: string) => ({
          url: `${configs?.endpoints?.retrieveApplicationHistory}/${logId}`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const { useGetApplicationHistoryMutation, useGetApplicationHistoryByIdQuery } = applicationHistoryApiSlice;
