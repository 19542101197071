import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PermissionsActionType, PermissionsStateType } from './types';

const initialState: PermissionsStateType = {
  userPermissions: [
    {
      action: '',
      subject: '',
      reason: '',
    },
  ],
};

const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissionsData: (
      state,
      { payload: { userPermissions } }: PayloadAction<PermissionsActionType>,
    ) => {
      state.userPermissions = userPermissions;
    },
  },
});
export const { setPermissionsData } = slice.actions;
export default slice.reducer;
