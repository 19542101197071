import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Select,
} from 'antd';
import potfLogo from '../../../assets/potf-logo.png';
import './TenantLogin.less';

function TenantLogin() {
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  return (
    <section id="TenantLogin">
      <div className="TenantLogin__container">
        <div className="TenantLogin__container--logo">
          <img width="300" src={potfLogo} alt="Logo" />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="tenant"
          >
            <Select
              showSearch
              placeholder="Select a Tenant"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
              options={[
                {
                  value: '1',
                  label: 'Tenant 1',
                },
                {
                  value: '2',
                  label: 'Tenant 2',
                },
                {
                  value: '3',
                  label: 'Tenant 3',
                },
              ]}
            />
          </Form.Item>

          <Form.Item>
            <Button type="dashed" htmlType="submit" className="TenantLogin__container--button">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}

export default TenantLogin;
