// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  POST_APPLICATION, POST_APPLICATION_DEFECTS, PostApplicationDefectInterface, PostApplicationDefectPayloadAction, PostApplicationInterface, PostApplicationPayloadAction,
} from './types';

const initialDefect: PostApplicationDefectInterface = {
  [POST_APPLICATION_DEFECTS.PRESENCE_OF_DEFECTS]: '',
  [POST_APPLICATION_DEFECTS.SEVERITY]: '',
};

const initialState: PostApplicationInterface = {
  [POST_APPLICATION.EXCEED_REPAIR_DESIGN_LIFE_TIME]: '',
  [POST_APPLICATION.COMPLIANCE_TO_IMP]: '',
  [POST_APPLICATION.DEFECTS]: [initialDefect],
};

const postApplicationSlice = createSlice({
  name: 'postApplication', // slice name
  initialState, // initial state
  reducers: { // reducers
    updatePostApplicationState(state, action: PayloadAction<PostApplicationPayloadAction>) {
      state[action.payload.type] = action.payload.payload;
    },
    addNewPostApplicationDefect(state) { // a reducer with the action creator
      state[POST_APPLICATION.DEFECTS].push({ ...initialDefect });
    },
    updatePostApplicationDefects(state, action: PayloadAction<PostApplicationDefectPayloadAction>) { // a reducer with the action creator
      state[POST_APPLICATION.DEFECTS][action.payload.index][action.payload.key] = action.payload.payload;
    },
    deletePostApplicationDefects(state, action: PayloadAction<number>) { // a reducer with the action creator
      state[POST_APPLICATION.DEFECTS].splice(action.payload, 1);
    },
  },
});

export const {
  updatePostApplicationState, addNewPostApplicationDefect, updatePostApplicationDefects, deletePostApplicationDefects,
} = postApplicationSlice.actions;
export default postApplicationSlice.reducer;
