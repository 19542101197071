import { createApi } from '@reduxjs/toolkit/query/react';
import { ReClassificationAssessmentsSearchResponse } from './types';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import configs from '../../app/configs';
import { RLTEAssesmentQueryParams } from '../lifetime-extension/types';

export const ReClassificationApi = createApi({
  reducerPath: 'ReClassificationApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addAssessment: builder.mutation({
        query: (payload) => ({
          url: configs.endpoints?.reClassificationAssessments,
          method: 'POST',
          body: payload,
        }),
      }),
      updateRCAssessment: builder.mutation({
        query: (payload) => ({
          url: configs.endpoints?.reClassificationAssessments,
          method: 'PATCH',
          body: payload,
        }),
      }),
      getReClassById: builder.query<any, string>({
        query(reClassId: string) {
          return { url: `${configs?.endpoints?.reClassificationAssessments}/${reClassId}` };
        },
      }),
      getAllReClassificationAssesments: builder.query<ReClassificationAssessmentsSearchResponse, RLTEAssesmentQueryParams | void>({
        query(queryParams) {
          const options: any = { url: configs?.endpoints?.reClassificationAssessments };

          if (queryParams) {
            options.params = queryParams;
          }

          return options;
        },
      }),
    };
  },
});

export const {
  useAddAssessmentMutation,
  useGetReClassByIdQuery,
  useGetAllReClassificationAssesmentsQuery,
  useUpdateRCAssessmentMutation,
} = ReClassificationApi;
