import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { YesNo } from '../../../utils/RBI-C/rbic.types';
import {
  IApiRbic,
  RbicHistoryAsset,
  RbicHistoryCalculation,
  RbicHistoryCompositeWrap,
  RbicHistoryDesignEngineering,
  RbicHistoryImpData,
  RbicHistoryOpertationType,
  RbicHistoryPostApplication,
  RbicHistoryState,
} from './types';

const initialState: RbicHistoryState = {
  operation: 'default',
  selectedRbic: null,
};

const rbicHistorySlice = createSlice({
  name: 'rbicHistory',
  initialState,
  reducers: {
    setRbicHistoryOperation: (state, action: PayloadAction<RbicHistoryOpertationType>) => {
      state.operation = action.payload;
    },
    setRbic: (state, action: PayloadAction<IApiRbic>) => {
      state.selectedRbic = action.payload;
    },
    setRbicHistoryAsset: (state, action: PayloadAction<RbicHistoryAsset>) => {
      const asset = action.payload;
      if (!state.selectedRbic) return;
      state.selectedRbic = {
        ...state.selectedRbic,
        company: {
          companyName: asset.company.companyName,
          id: asset.company.id,
        },
        Cof: { cofId: asset.Cof.cofId },
        equipmentType: {
          equipmentName: asset.equipmentType.equipmentName,
          id: asset.equipmentType.id,
        },
        equipTag: asset.equipTag,
        facility: asset.facility,
      };
    },
    setRbicHistoryManufacturing: (state, action: PayloadAction<YesNo>) => {
      if (!state.selectedRbic) return;
      state.selectedRbic.productQualificationRecord = action.payload;
    },
    setRbicHistoryDesignEngineering: (state, action: PayloadAction<RbicHistoryDesignEngineering>) => {
      if (!state.selectedRbic) return;
      state.selectedRbic = {
        ...state.selectedRbic,
        inspectionDataAvailability: action.payload.inspectionDataAvailability,
        repairType: action.payload.repairType,
      };
    },
    setRbicHistoryCompositeWrap: (state, action: PayloadAction<RbicHistoryCompositeWrap>) => {
      const {
        documentHistory,
        evidenceOfSOP,
        qualityCheckRecord,
      } = action.payload;
      if (!state.selectedRbic) return;
      state.selectedRbic = {
        ...state.selectedRbic,
        documentHistory,
        evidenceOfSOP,
        qualityCheckRecord,
      };
    },
    setRbicHistoryPostApplication: (state, action: PayloadAction<RbicHistoryPostApplication>) => {
      const {
        complienceToIMP,
        defects,
        exceedRepairDesignLifetime,
      } = action.payload;

      if (!state.selectedRbic) return;

      state.selectedRbic = {
        ...state.selectedRbic,
        complienceToIMP,
        defects,
        exceedRepairDesignLifetime,
      };
    },

    setRbicHistoryCalculationResult: (state, action: PayloadAction<RbicHistoryCalculation>) => {
      if (!state.selectedRbic) return;
      state.selectedRbic = {
        ...state.selectedRbic,
        Cof: { cofId: Number(action.payload.cof) },
        pof: action.payload.pof,
      };

      state.selectedRbic.IMP = {
        ...state.selectedRbic.IMP,
        risk: action.payload.risk,
        applicationLocation: '',
        tableData: '[]',
        intervalMonth: undefined,
        intervalYear: undefined,
        warrantyPeriodMonth: undefined,
        warrantyPeriodYear: undefined,
        compositeInstallationDate: '',
      };
    },
    setRbicHistoryIMPApplicationLocation: (state, action: PayloadAction<string>) => {
      if (!(state.selectedRbic && state.selectedRbic.IMP)) return;
      state.selectedRbic.IMP.applicationLocation = action.payload;
    },
    setRbicHistoryIMPData: (state, action: PayloadAction<RbicHistoryImpData>) => {
      const {
        applicationLocation,
        compositeInstallationDate,
        intervalMonth,
        intervalYear,
        tableData,
        warrantyPeriodMonth,
        warrantyPeriodYear,
      } = action.payload;
      if (!(state.selectedRbic && state.selectedRbic.IMP)) return;

      if (applicationLocation === 'Buried - External Corrosion') {
        state.selectedRbic.IMP = {
          ...state.selectedRbic.IMP,
          intervalYear,
          intervalMonth,
          tableData: tableData ?? '[]', // If 'Buried - External Corrosion' is passed, obviously it'll generate tableData
        };
      } else {
        state.selectedRbic.IMP = {
          ...state.selectedRbic.IMP,
          warrantyPeriodMonth,
          warrantyPeriodYear,
          compositeInstallationDate,
          tableData: tableData ?? '[]',
        };
      }
    },
  },
});

export const {
  setRbic,
  setRbicHistoryAsset,
  setRbicHistoryManufacturing,
  setRbicHistoryDesignEngineering,
  setRbicHistoryCompositeWrap,
  setRbicHistoryPostApplication,
  setRbicHistoryCalculationResult,
  setRbicHistoryIMPApplicationLocation,
  setRbicHistoryIMPData,
  setRbicHistoryOperation,
} = rbicHistorySlice.actions;

export default rbicHistorySlice.reducer;
