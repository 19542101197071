export enum DOCUMENTATION_FORM_INPUTS {
  DESIGN_REPORT = 'designReport',
  FIELD_DESIGN_REPORT = 'fieldDesignReport',
  INSTALLATION_REPORT = 'installationReport',
  FIELD_INSTALLATION_REPORT = 'fieldInstallationReport',
  POST_INSTALLATION_INSPECTION_REPORT = 'postInstallationInspectionReport',
  FIELD_POST_INSTALLATION_INSPECTION_REPORT = 'fieldPostInstallReport',
  PRODUCT_QUALIFICATION_REPORT = 'productQualificationReport',
  FIELD_QUALIFICATION_REPORT = 'fieldQualificationReport',
  SUBSTRATE_INSPECTION_REPORT = 'substrateInspectionReport',
  FIELD_SUBSTRATE_INSPECTION_REPORT = 'fieldSubstrateInspectionReport',
  COMPOSITE_REPAIR_DESIGN_DATASHEET = 'compositeRepairDesignDatasheet',
  FIELD_DESIGN_DATASHEET = 'fieldDesignDatasheet',
  COMPOSITE_CLOSEOUT_REPORT = 'compositeCloseoutReport',
  FIELD_CLOSEOUT_REPORT = 'fieldCloseOutReport',
  SURFACE_PREPARATION_RECORD = 'surfacePreparationRecord',
  FIELD_SURFACE_PREPARATION_RECORD = 'fieldSurfacePrepRec',
  QA_QC_REPORT = 'qaqcReport',
  FIELD_QA_QC_REPORT = 'fieldQaqcReport',
  INTEGRITY_MANAGEMENT_PLAN = 'imp',
  FIELD_IMP_DOC = 'fieldImpDoc',
  DRAWING_REPORT = 'drawingReport',
  FIELD_DRAWING_REPORT = 'fieldDrawingReport',
}

export const DOCUMENTATION_TYPES = {
  COMPOSITE_CLOSE_OUT_REPORT: 'COMPOSITE_CLOSE_OUT_REPORT',
  COMPOSITE_REPAIR_DESIGN_DATASHEET: 'COMPOSITE_REPAIR_DESIGN_DATASHEET',
  DESIGN_REPORT: 'DESIGN_REPORT',
  INTEGRITY_MANAGEMENT_PLAN: 'INTEGRITY_MANAGEMENT_PLAN',
  INSTALLATION_REPORT: 'INSTALLATION_REPORT',
  POST_INSTALLATION_INSPECTION_REPORT: 'POST_INSTALLATION_INSPECTION_REPORT',
  QAQC_REPORT: 'QA/QC_REPORT',
  PRODUCT_QUALIFICATION_REPORT: 'PRODUCT_QUALIFICATION_REPORT',
  SUBSTRATE_INSPECTION_REPORT: 'SUBSTRATE_INSPECTION_REPORT',
  SURFACE_PREPARATION_RECORD: 'SURFACE_PREPARATION_RECORD',
  DRAWING_REPORT: 'DRAWING_REPORT',
};

export interface DocumentationTitleInterface {
  [DOCUMENTATION_FORM_INPUTS.DESIGN_REPORT]: string;
  [DOCUMENTATION_FORM_INPUTS.INSTALLATION_REPORT]: string;
  [DOCUMENTATION_FORM_INPUTS.POST_INSTALLATION_INSPECTION_REPORT]: string;
  [DOCUMENTATION_FORM_INPUTS.PRODUCT_QUALIFICATION_REPORT]: string;
  [DOCUMENTATION_FORM_INPUTS.SUBSTRATE_INSPECTION_REPORT]: string;
  [DOCUMENTATION_FORM_INPUTS.COMPOSITE_REPAIR_DESIGN_DATASHEET]: string;
  [DOCUMENTATION_FORM_INPUTS.COMPOSITE_CLOSEOUT_REPORT]: string;
  [DOCUMENTATION_FORM_INPUTS.SURFACE_PREPARATION_RECORD]: string;
  [DOCUMENTATION_FORM_INPUTS.QA_QC_REPORT]: string;
  [DOCUMENTATION_FORM_INPUTS.INTEGRITY_MANAGEMENT_PLAN]: string;
}

export interface DocumentationFileInterface {
  [DOCUMENTATION_FORM_INPUTS.FIELD_DESIGN_REPORT]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_INSTALLATION_REPORT]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_POST_INSTALLATION_INSPECTION_REPORT]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_QUALIFICATION_REPORT]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_SUBSTRATE_INSPECTION_REPORT]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_DESIGN_DATASHEET]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_CLOSEOUT_REPORT]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_SURFACE_PREPARATION_RECORD]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_QA_QC_REPORT]?: File;
  [DOCUMENTATION_FORM_INPUTS.FIELD_IMP_DOC]?: File;
}
