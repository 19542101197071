// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userEmail: '',
  userCompany: '',
  parentId: '',
  userId: '',
};

const submissionApprovalSlice = createSlice({
  name: 'PendingApproval', // slice name
  initialState, // initial state
  reducers: { // reducers
    setCurrentUserEmail(state, action) {
      state.userEmail = action.payload;
    },
    setCurrentUserCompany(state, action) {
      state.userCompany = action.payload;
    },
    setCurrentUserId(state, action) {
      state.userId = action.payload;
    },
    setParentId(state, action) {
      state.parentId = action.payload;
    },
  },
});

export const {
  setCurrentUserEmail,
  setCurrentUserCompany,
  setCurrentUserId,
  setParentId,
} = submissionApprovalSlice.actions;
export default submissionApprovalSlice.reducer;
