const AAD_CLIENT_ID_FE = process.env.REACT_APP_AAD_CLIENT_ID_FE ?? '';
const AAD_CLIENT_ID_BE = process.env.REACT_APP_AAD_CLIENT_ID_BE ?? '';
const AAD_TENANT_ID = process.env.REACT_APP_AAD_TENANT_ID ?? '';

const configs = {
  environment: process.env.REACT_APP_NODE_ENV ?? 'local',
  // api_uri: {
  //   local: 'http://localhost:7071/api',
  //   dev: 'https://ptazsg-4corimsap01.azurewebsites.net/api',
  //   stage: 'https://ptsg-4corimsds-apim.azure-api.net/api',
  //   stage_old: 'https://ptsg-4corimsdsfunc01.azurewebsites.net/api',
  //   prod: 'https://api.corimsds.petronas.com/v1',
  // },
  // uri: {
  //   local: 'http://localhost:5000',
  //   dev: 'https://ptazsg-4corims-ap01.azurewebsites.net',
  //   stage: 'https://corimsdsqa.petronas.com',
  //   stage_old: 'https://ptsg-4corimsds-wb01.azurewebsites.net',
  //   prod: 'https://corimsds.petronas.com',
  // },
  aad: {
    clientIdFe: AAD_CLIENT_ID_FE,
    clientIdBe: AAD_CLIENT_ID_BE,
    authority: `https://login.microsoftonline.com/${AAD_TENANT_ID}`,
    apiScope: `api://${AAD_CLIENT_ID_BE}/App.Read`,
  },
  endpoints: {
    appConfig: '/app-management/config',
    applicationMethods: '/application-method-management/application-methods',
    assets: '/asset-management/assets',
    assetBulk: 'asset-management/multiple-assets',
    azureFile: '/azure-file/download',
    assetDropDown: '/asset-management/asset-dropdown',
    attributes: '/attribute-management/attributes',
    axialExtentRepairs: '/axial-extent-repair-management/axial-extent-repairs',
    companies: '/company-management/companies',
    compositeWraps: '/composite-wrap-management/composite-wraps',
    extNotPipelineCompaniesList: '/uat/pet/iassure/assetmaintmgmt/pxy/api/v1/api/Corims/GetCompanies',
    compositeRepairData: '/composite-data-management/composite-data',
    compositeRepairDataBulk: '/composite-data-management/composite-data-bulk',
    compositeRepairDataFilter: '/repair-data-management/filter',
    assetDataFilter: '/asset-data-management/filter',
    repairDesignDataFilter: '/repair-design-management/filter',
    assetDataWithRepairFilter: '/asset-data-management/filter-repair',
    dashboards: '/dashboard-management',
    dashboardRepairDesignIso: '/dashboard-management/repair-design-iso',
    dashboardRepairDesign: '/dashboard-management/repair-design',
    dashboardRbic: '/dashboard-management/rbic',
    dashboardRepairType: '/dashboard-management/repair-type',
    dashboardSts: '/dashboard-management/sts',
    dashboardEquipmentType: '/dashboard-management/repair-equipment-type',
    dashboardRepairExceedMOC: '/dashboard-management/repair-moc',
    defectInfo: '/defect-info-management/defect-info',
    defectTypes: '/defect-type-management/defect-types',
    designCodes: '/design-code-management/design-codes',
    designOutput: '/design-output-management/design-output',
    designSpecs: '/design-spec-management/design-specs',
    documentations: '/documentation-management/documentations',
    equipmentTypes: '/equipment-type-management/equipment-types',
    externalLoadInfo: '/external-load-info-management/external-load-info',
    features: '/feature-management/features',
    imp: '/imp-management/imp',
    laminateInfo: '/laminate-info-management/laminate-info',
    materialGrades: '/material-grade-management/material-grades',
    materialSpecs: '/material-spec-management/material-specs',
    materialTypes: '/material-type-management/material-types',
    modules: '/module-management/modules',
    saveProductDataBulk: '/product-management/products-bulk',
    treeData: '/module-management/tree-data',
    oddGeometries: '/odd-geometry-management/odd-geometries',
    // presenceDefects: '/presence-defect-management/defects',
    // prim: '/prim-management/prim',
    products: '/product-management/products',
    rbic: '/rbic-management/rbic',
    rbicBulk: '/rbic-management/rbic-bulk',
    rbicDropdown: '/rbic-management/rbic-dropdown-data',
    repairClasses: '/repair-class-management/repair-classes',
    CoRsToRbicMapping: '/dashboard-management/rbic-mapping',
    repairDesignISOBulk: '/repair-design-management/multiple-repair-designs/iso',
    repairDesigns: '/repair-design-management/repair-designs',
    repairTypes: '/repair-type-management/repair-types',
    repairs: '/repair-management/repairs',
    stsInstallationStatus: 'prim-management/prim/sts-status',
    stsPrim: '/prim-management/prim',
    stsQRGenerated: '/prim-management/prim/sts-qr-generated',
    primStatus: '/prim-management/prim/status',
    tempPrims: '/prim-management/prim/temp-prims',
    repairDropdown: '/repair-management/repair-dropdown-data',
    repairDesignDropdown: 'repair-design-management/dropdown-data',
    addDoc: '/documentation-management/documentations',
    rlteAssessments: '/rlte-assessment-management/rlte-assessments',
    rlteAssessmentDropdown: '/rlte-assessment-management/dropdown-data',
    reClassificationAssessments: '/re-classification-assessment-management/re-classification-assessments',
    roles: '/role-management/roles',
    // severityDefects: '/severity-defect-management/defects',
    substrateInfo: '/substrate-info-management/substrate-info',
    surfaceConditions: '/surface-condition-management/surface-conditions',
    surfacePreparations: '/surface-preparation-management/surface-preparations',
    users: '/user-management/users',
    usersByCompany: '/user-management/users/company',
    userData: '/user-management/user-data',
    cof: '/cof-management/cof',
    ipimsCompaniesList: 'ipims/asset-management/region-info',
    ipimsAccessToken: 'ipims/ex-app-token/access-token',
    ipimsCombinedData: 'ipims/asset-management/combined-info',
    ipimsPipelineList: 'ipims/asset-management/pipline-list',
    ipimsCompositeRepairList: 'ipims/asset-management/composite-repair-list',
    iassureCompanyList: 'iassure/asset-management/company-list',
    iassureComponentDetails: 'iassure/asset-management/component-details',
    integrityMapping: '/mapping-management/lifetime-extension',
    repairLifetimeExtensionPeriod: '/mapping-management/repair-lifetime-period',
    impcombination: '/imp-management/impcombination',
    impcombination2: '/imp-management/impcombination2',
    applicationDraft: '/application-draft-management/draft',
    retrieveApplicationDraft: '/application-draft-management/retrieve-draft',
    retrieveApplicationHistory: '/application-history-management/history',
    prim: {
      getPresenceOfDefects: '/presence-defect-management/defects',
      getSeverityOfDefects: '/severity-defect-management/defects',
      savePrim: '/prim-management/prim',
    },
  },
  api_base: `${(process.env.REACT_APP_BE_HOST ?? '')}`,
};

export const API_BASE_URL = `${(configs.api_base)}/${(configs.environment === 'prod' ? 'v1' : 'api')}`; // configs.api_uri[environment as keyof IEnv];
export const FRONTEND_BASE_URL = process.env.REACT_APP_FE_HOST ?? ''; // configs.uri[environment as keyof IEnv];

export default configs;
