import { useMsal } from '@azure/msal-react';
import { Button, Result } from 'antd';
import logout from '../../utils/auth/handleLogout';
import '../../styles/antdLibStyles.less';

function Forbidden() {
  const { instance, accounts } = useMsal();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you don't have access, please contact CoRIMS DS administrator for assistance."
      extra={<Button type="primary" onClick={() => logout('redirect', instance, accounts?.[0])}>Logout</Button>}
    />
  );
}

export default Forbidden;
