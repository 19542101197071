import { createApi } from '@reduxjs/toolkit/query/react';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import configs from '../../app/configs';
import { ICofResponse } from './types';

export const cofApi = createApi({
  reducerPath: 'cofApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return { fetchCof: builder.query<ICofResponse, void>({ query: () => configs.endpoints?.cof }) };
  },
});

export const { useFetchCofQuery } = cofApi;
