// DUCKS pattern
import { createApi } from '@reduxjs/toolkit/query/react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EXTERNAL_LOADS_INFORMATION_FORM_INPUTS } from '../../../../form-constants/ExternalLoadsInformation';
import {
  ExternalLoadsInformationInterface, IExternalLoadsInformationPayload, PayloadActionType,
} from './types';
import configs from '../../../../app/configs';
import rtkFetchWrapper from '../../../../utils/rtkFetchWrapper';

const initialState: ExternalLoadsInformationInterface = {
  [EXTERNAL_LOADS_INFORMATION_FORM_INPUTS.ENABLE_EXTERNAL_LOADS_INFORMATION]: 'disabled',
  [EXTERNAL_LOADS_INFORMATION_FORM_INPUTS.EXTERNAL_OR_VACUUM_PRESSURE]: '',
  [EXTERNAL_LOADS_INFORMATION_FORM_INPUTS.EXTERNAL_OR_VACUUM_PRESSURE_UNIT]: 'Bar',
  [EXTERNAL_LOADS_INFORMATION_FORM_INPUTS.BURIAL_DEPTH_TO_CENTER_LINE]: '',
  [EXTERNAL_LOADS_INFORMATION_FORM_INPUTS.BURIAL_DEPTH_TO_CENTER_LINE_UNIT]: 'mm',
};

const externalLoadsInformationSlice = createSlice({
  name: 'externalLoadsInformation', // slice name
  initialState, // initial state
  reducers: { // reducers
    setExternalLoadsInformationSliceValue(state: ExternalLoadsInformationInterface, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const externalLoadsInformationApi = createApi({
  reducerPath: 'eliApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addExternalLoadsInfo: builder.mutation({
        query: (payload: IExternalLoadsInformationPayload) => ({
          url: configs?.endpoints?.externalLoadInfo,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { setExternalLoadsInformationSliceValue } = externalLoadsInformationSlice.actions;
export default externalLoadsInformationSlice.reducer;
export const { useAddExternalLoadsInfoMutation } = externalLoadsInformationApi;
