// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CounterState {
  value: number;
}

const initialState: CounterState = { value: 0 };

const counterSlice = createSlice({
  name: 'counter', // slice name
  initialState, // initial state
  reducers: { // reducers
    incremented(state) { // increment reducer
      // We don't need to do something like this
      // return state.value + 1;
      // it's okay to do this because immer makes it immutable
      // under the hood
      state.value += 1;
    },
    amountAdded(state, action: PayloadAction<number>) { // a reducer with the action creator
      state.value += action.payload;
    },
  },
});

export const { incremented, amountAdded } = counterSlice.actions;
export default counterSlice.reducer;
