import { createApi } from '@reduxjs/toolkit/query/react';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';

export const applicationDraftApiSlice = createApi({
  reducerPath: 'applicationDraftApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addApplicationDraft: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.applicationDraft,
          method: 'POST',
          body: payload,
        }),
      }),
      getApplicationDraft: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.retrieveApplicationDraft,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const {
  useAddApplicationDraftMutation,
  useGetApplicationDraftMutation,
} = applicationDraftApiSlice;
