// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { LAMINATE_INFORMATION_FORM_INPUTS } from '../../../../form-constants/LaminateInformation';
import {
  ILaminateInfoPayload, LaminateInformationInterface, PayloadActionType,
} from './types';
import configs from '../../../../app/configs';
import rtkFetchWrapper from '../../../../utils/rtkFetchWrapper';

const initialState: LaminateInformationInterface = {
  [LAMINATE_INFORMATION_FORM_INPUTS.COMPOSITE_REPAIR_BRAND]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.PRODUCT_NAME]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.MANUFACTURER]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.LAMINATE_WIDTH]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.LAMINATE_WIDTH_UNIT]: 'inches',
  [LAMINATE_INFORMATION_FORM_INPUTS.TENSILE_MODULUS_OF_LAMINATE_AXIAL]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.TENSILE_MODULUS_OF_LAMINATE_CIRCUMFERENTIAL]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.COMBINED_TENSILE_MODULUS]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.SHEAR_MODULUS_OF_LAMINATE]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.THERMAL_EXPANSION_COEFFICIENT_AXIAL]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.THERMAL_EXPANSION_COEFFICIENT_CIRCUMFERENTIAL]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.GLASS_TRANSITION_TEMPERATURE]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.THICKNESS_OF_AN_INDIVIDUAL_LAYER_OF_LAMINATE]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.THICKNESS_OF_AN_INDIVIDUAL_LAYER_OF_LAMINATE_UNIT]: 'inches',

  [LAMINATE_INFORMATION_FORM_INPUTS.CONFIDENCE_LIMIT_OF_ENERGY_RELEASE_RATE]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.LAP_SHEAR_STRENGTH_BETWEEN_LAMINATE_AND_SUBSTRATE]: '',
  [LAMINATE_INFORMATION_FORM_INPUTS.POISSONS_RATIO_OF_LAMINATE]: '',
};

const laminateInformationSlice = createSlice({
  name: 'laminateInformation', // slice name
  initialState, // initial state
  reducers: { // reducers
    setLaminateInformationValue(state: LaminateInformationInterface, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const addLaminateInfoSlice = createApi({
  reducerPath: 'rlteAssessmentApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addLaminateInfo: builder.mutation({
        query: (payload: ILaminateInfoPayload) => ({
          url: configs?.endpoints?.laminateInfo,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { setLaminateInformationValue } = laminateInformationSlice.actions;
export default laminateInformationSlice.reducer;
export const { useAddLaminateInfoMutation } = addLaminateInfoSlice;
