export enum DESIGN_REVALIDATION_FORM_INPUTS {
  REPORTED_IN_RBIC = 'reportedInRBIC',
  DEFECT_ON_COMPOSITE = 'defectOnComposite',
  LIFETIME_EXTENSION_PERIOD = 'lifetimeExtensionPeriod',
}

export interface DesignRevalidationInterface {
  [DESIGN_REVALIDATION_FORM_INPUTS.REPORTED_IN_RBIC]: string;
  [DESIGN_REVALIDATION_FORM_INPUTS.DEFECT_ON_COMPOSITE]: string;
  [DESIGN_REVALIDATION_FORM_INPUTS.LIFETIME_EXTENSION_PERIOD]: string;
}

export type DesignRevalidationKeys = keyof DesignRevalidationInterface;

export interface PayloadActionType {
  type: DesignRevalidationKeys;
  payload: string;
}
