// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AXIAL_EXTENT_OF_REPAIR_FORM_INPUTS } from '../../../../form-constants/AxialExtentOfRepair';
import configs from '../../../../app/configs';
import {
  AxialExtentOfRepairInterface, IAxialExtentOfRepairPayload, PayloadActionType,
} from './types';
import rtkFetchWrapper from '../../../../utils/rtkFetchWrapper';

const initialState: AxialExtentOfRepairInterface = {
  [AXIAL_EXTENT_OF_REPAIR_FORM_INPUTS.CODE_FOR_REPAIR_DESCRIPTION]: '',
  [AXIAL_EXTENT_OF_REPAIR_FORM_INPUTS.AXIAL_EXTENT_AVAILABLE_ON_SUBSTRATE_1]: '',
  [AXIAL_EXTENT_OF_REPAIR_FORM_INPUTS.AXIAL_EXTENT_AVAILABLE_ON_SUBSTRATE_1_UNIT]: 'mm',
  [AXIAL_EXTENT_OF_REPAIR_FORM_INPUTS.AXIAL_EXTENT_AVAILABLE_ON_SUBSTRATE_2]: '',
  [AXIAL_EXTENT_OF_REPAIR_FORM_INPUTS.AXIAL_EXTENT_AVAILABLE_ON_SUBSTRATE_2_UNIT]: 'mm',
};

const axialExtentOfRepairSlice = createSlice({
  name: 'oddGeometrySpecification', // slice name
  initialState, // initial state
  reducers: { // reducers
    setAxialExtentOfRepairValue(state: AxialExtentOfRepairInterface, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const AERSlice = createApi({
  reducerPath: 'axialExtendRepairApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addAER: builder.mutation({
        query: (payload: IAxialExtentOfRepairPayload) => ({
          url: configs?.endpoints?.axialExtentRepairs,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { setAxialExtentOfRepairValue } = axialExtentOfRepairSlice.actions;
export default axialExtentOfRepairSlice.reducer;
export const { useAddAERMutation } = AERSlice;
