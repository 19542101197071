import { UploadFile } from 'antd/es/upload/interface';

export enum RISK_ASSESSMENT_FORM_INPUTS {
  RISK = 'risk',
  IS_FILE_UPLOADED = 'isFileUploaded',
  IPSS_RISK_RATING = 'ipssRiskRating',
  CORIMS_RBIC_SUMMARY = 'corimsRbicSummary',
  FIELD_RISK_ASSESSMENT_REPORT = 'fieldRiskAssessmentReport'
}

export interface RiskAssessmentInterface {
  [RISK_ASSESSMENT_FORM_INPUTS.RISK]: string;
  [RISK_ASSESSMENT_FORM_INPUTS.IS_FILE_UPLOADED]: string;
  [RISK_ASSESSMENT_FORM_INPUTS.IPSS_RISK_RATING]: string;
  [RISK_ASSESSMENT_FORM_INPUTS.CORIMS_RBIC_SUMMARY]: string;
  [RISK_ASSESSMENT_FORM_INPUTS.FIELD_RISK_ASSESSMENT_REPORT]: UploadFile | null;
}

export type RiskAssessmentKeys = keyof RiskAssessmentInterface;

export interface PayloadActionType {
  type: RiskAssessmentKeys;
  payload: string | UploadFile | null;
}
