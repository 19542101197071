import { createApi } from '@reduxjs/toolkit/query/react';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import {
  Role,
  RolePayload,
  UpdateRolePayload,
} from './types';

export const roleApiSlice = createApi({
  reducerPath: 'rolesApi',
  baseQuery: rtkFetchWrapper(),
  tagTypes: ['Users', 'Roles'],
  endpoints(builder) {
    return {
      fetchRoles: builder.query<Role, string | void>({
        query: (email?: string) => {
          const options: any = { url: configs?.endpoints?.roles };

          if (email) {
            options.params = { email };
          }

          return options;
        },
        providesTags: ['Roles'],
      }),
      addRole: builder.mutation({
        query: (payload: RolePayload) => ({
          url: configs?.endpoints?.roles,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['Roles'],
      }),
      updateRole: builder.mutation({
        query: (payload: UpdateRolePayload) => ({
          url: configs?.endpoints?.roles,
          method: 'PATCH',
          body: payload,
        }),
        invalidatesTags: ['Roles'],
      }),
      deleteRole: builder.mutation({
        query: (roleId: string) => ({
          url: `${configs.endpoints.roles}/${roleId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Users', 'Roles'],
      }),
    };
  },
});

export const {
  useFetchRolesQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleApiSlice;
