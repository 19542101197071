import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { DataNode } from 'antd/lib/tree';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';

interface IPayload {
  success: boolean
  data: DataNode[]
}

export const moduleApiSlice = createApi({
  reducerPath: 'moduleApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return { fetchTreeData: builder.query<IPayload, void>({ query: () => configs?.endpoints?.treeData }) };
  },
});

export const { useFetchTreeDataQuery } = moduleApiSlice;
