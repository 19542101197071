export enum DESIGN_SPECIFICATION_FORM_INPUTS {
  DESIGN_INTERNAL_PRESSURE = 'designInternalPressure',
  DESIGN_INTERNAL_PRESSURE_UNIT = 'designInternalPressure_unit',
  INTERNAL_PRESSURE_DURING_APPLICATION_OF_REPAIR = 'internalPressureDuringApplicationofRepair',
  INTERNAL_PRESSURE_DURING_APPLICATION_OF_REPAIR_UNIT = 'internalPressureDuringApplicationofRepair_unit',
  INTERNAL_PRESSURE_AFTER_APPLICATION_OF_REPAIR = 'internalPressureAfterApplicationofRepair',
  INTERNAL_PRESSURE_AFTER_APPLICATION_OF_REPAIR_UNIT = 'internalPressureAfterApplicationofRepair_unit',
  TEMPERATURE_DURING_APPLICATION_OF_REPAIR = 'temparatureDuringApplicationofRepair',
  TEMPERATURE_AFTER_APPLICATION_OF_REPAIR = 'temparatureAfterApplicationofRepair',
  APPLIED_AXIAL_LOAD = 'appliedAxialLoad',
  APPLIED_SHEAR_LOAD = 'appliedShearLoad',
  APPLIED_SHEAR_MOMENT = 'appliedShearMoment',
  APPLIED_TORSIONAL_MOMENT = 'appliedTorsionalMoment',
  DESIGN_TEMPERATURE = 'designTemparature',
  INSTALLATION_TEMPERATURE = 'installationTemparature',
  CORROSION_PROTECTION_REQUIREMENT = 'corrosionProtectionRequirement',
}
