// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  repairDesignInputs: {},
  primsInputs: {
    assetDataId: null,
    coinTapTest: '',
    compositeRepairHistory: '',
    compositeRepairHistoryFile: null,
    hardnessTest: '',
    lastInspectionDate: '',
    ndt: '',
    nextInspectionDate: '',
    podId: null,
    podOther: '',
    primReport: null,
    sodId: null,
    sodOther: '',
    visualInspection: '',
    remarksPrim: '',
    inspectionPhoto: '',
    inspectionPhotoReport: null,
    stsInstallationStatus: '',
  },
  compositeWrapInputs: {},
  documentationInputs: {},
  ipimsRepairDesignInput: {},
  ipimsPrimInput: {},
  ipimsCompositeWrapDataInput: {},
  ipimsDocumentDataInput: {},
  ipimsOperationInput: '',
};

const compositeRepairRegistrationSlice = createSlice({
  name: 'compositeRepairRegistration', // slice name
  initialState, // initial state
  reducers: { // reducers
    setRepairDesignInCompositeRepair(state, action) {
      state.repairDesignInputs = action.payload;
    },
    setPrimsInCompositeRepair(state, action) {
      state.primsInputs = action.payload;
    },
    setCompositeWrapInCompositeRepair(state, action) {
      state.compositeWrapInputs = action.payload;
    },
    setDocumentationInCompositeRepair(state, action) {
      state.documentationInputs = action.payload;
    },
    setIpimsInRepairDesign(state, action) {
      state.ipimsRepairDesignInput = action.payload;
    },
    setIpimsInPrim(state, action) {
      state.ipimsPrimInput = action.payload;
    },
    setIpimsInCompositeWrapData(state, action) {
      state.ipimsCompositeWrapDataInput = action.payload;
    },
    setIpimsInDocumentData(state, action) {
      state.ipimsDocumentDataInput = action.payload;
    },
    setIpimsInOperation(state, action) {
      state.ipimsOperationInput = action.payload;
    },
  },
});

export const {
  setRepairDesignInCompositeRepair,
  setPrimsInCompositeRepair,
  setCompositeWrapInCompositeRepair,
  setDocumentationInCompositeRepair,
  setIpimsInRepairDesign,
  setIpimsInPrim,
  setIpimsInCompositeWrapData,
  setIpimsInDocumentData,
  setIpimsInOperation,
} = compositeRepairRegistrationSlice.actions;
export default compositeRepairRegistrationSlice.reducer;
