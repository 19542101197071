// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DESIGN_REVALIDATION_RECLASSIFICATION_FORM_INPUTS,
  DesignRevalidationReclassificationInterface,
  PayloadActionType,
} from './types';

export const initialState: DesignRevalidationReclassificationInterface = {
  [DESIGN_REVALIDATION_RECLASSIFICATION_FORM_INPUTS.CHANGES_IN_CURRENT_DEFECT]: 'no',
  [DESIGN_REVALIDATION_RECLASSIFICATION_FORM_INPUTS.VISUAL_DEFECT_OR_DAMAGE]: 'no',
  [DESIGN_REVALIDATION_RECLASSIFICATION_FORM_INPUTS.RECLASSIFICATION_STATUS]: 'temporary',
};

const DesignRevalidationReclassificationSlice = createSlice({
  name: 'DesignRevalidationReclassification', // slice name
  initialState, // initial state
  reducers: { // reducers
    setDesignRevalidationReclassificationValue(state: DesignRevalidationReclassificationInterface, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const { setDesignRevalidationReclassificationValue } = DesignRevalidationReclassificationSlice.actions;
export default DesignRevalidationReclassificationSlice.reducer;
