import { IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from '../../app/authConfig';

const handleLogin = async (
  loginType: 'popup' | 'redirect',
  instance: IPublicClientApplication,
) => {
  try {
    if (loginType === 'popup') {
      await instance.loginPopup(loginRequest);
    } else if (loginType === 'redirect') {
      await instance.loginRedirect(loginRequest);
    }
  } catch (e) {
    console.error(e);
  }
};

export default handleLogin;
