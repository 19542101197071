// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { SUBSTRATE_INFORMATION_FORM_INPUTS } from '../../../../form-constants/SubstrateInformation';
import {
  ISubstrateInfoPayload, PayloadActionType, SubstrateInformationState,
} from './types';
import configs from '../../../../app/configs';
import rtkFetchWrapper from '../../../../utils/rtkFetchWrapper';

export const initialState: SubstrateInformationState = {
  [SUBSTRATE_INFORMATION_FORM_INPUTS.DOCUMENT_NO]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.LOCATION]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.PROCESS_SERVICE]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.LIFE_TIME]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.COMPANY]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.EQUIPMENT_TAG]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.REPAIR_CLASS]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.ALLOWABLE_STRESS_OF_SUBSTRATE]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.ALLOWABLE_STRESS_OF_SUBSTRATE_UNIT]: 'psi',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.EQUIPMENT_TYPE]: 'Pipe',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.NOMINAL_PIPE_SIZE]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.NOMINAL_PIPE_WALL_THICKNESS]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.NOMINAL_PIPE_WALL_THICKNESS_UNIT]: 'mm',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.PIPE_GRADE]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.PIPE_SCHEDULE]: '',
  [SUBSTRATE_INFORMATION_FORM_INPUTS.NOMINAL_WALL_THICKNESS_UNIT]: 'mm',
};

const substrateInformationSlice = createSlice({
  name: 'substrateInformation', // slice name
  initialState, // initial state
  reducers: { // reducers
    setSubstrateInformationValue(state: SubstrateInformationState, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const subStrateInfoSlice = createApi({
  reducerPath: 'substrateInfoApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addSubstrateInfo: builder.mutation({
        query: (payload: ISubstrateInfoPayload) => ({
          url: configs?.endpoints?.substrateInfo,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { setSubstrateInformationValue } = substrateInformationSlice.actions;
export default substrateInformationSlice.reducer;
export const { useAddSubstrateInfoMutation } = subStrateInfoSlice;
