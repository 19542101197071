export enum SUBSTRATE_INFORMATION_FORM_INPUTS {
  DOCUMENT_NO = 'documentNo',
  LOCATION = 'location',
  PROCESS_SERVICE = 'processService',
  LIFE_TIME = 'lifetime',
  COMPANY = 'company',
  EQUIPMENT_TAG = 'equipTag',
  REPAIR_CLASS = 'repairClass',
  ALLOWABLE_STRESS_OF_SUBSTRATE = 'allowableStressOfSubstrate',
  ALLOWABLE_STRESS_OF_SUBSTRATE_UNIT = 'allowableStressOfSubstrate_unit',
  EQUIPMENT_TYPE = 'equipmentType',
  NOMINAL_PIPE_SIZE = 'nominalPipeSize',
  NOMINAL_PIPE_WALL_THICKNESS = 'nominalPipeWallThickness',
  NOMINAL_PIPE_WALL_THICKNESS_UNIT = 'nominalPipeWallThickness_unit',
  PIPE_GRADE = 'pipeGrade',
  PIPE_SCHEDULE = 'pipeSchedule',
  NOMINAL_SIZE = 'nominalSize',
  NOMINAL_WALL_THICKNESS = 'nominalWallThickness',
  NOMINAL_WALL_THICKNESS_UNIT = 'nominalWallThickness_unit',
  MATERIAL_GRADE = 'materialGrade'
}
