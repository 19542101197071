// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const initialState = { uniqueId: '', id: -1 };

const lifeTimeExtensionUniqueIdSlice = createSlice({
  name: 'lifeTimeExtensionUniqueId', // slice name
  initialState, // initial state
  reducers: { // reducers
    setLifeTimeExtensionUniqueId(state, action: PayloadAction<{ uniqueId: string, id: number }>) {
      state.uniqueId = action.payload.uniqueId;
      state.id = action.payload.id;
    },
  },
});

export const { setLifeTimeExtensionUniqueId } = lifeTimeExtensionUniqueIdSlice.actions;
export default lifeTimeExtensionUniqueIdSlice.reducer;
