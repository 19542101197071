import { useSearchParams } from 'react-router-dom';
import TenantLogin from './commercialization/TenantLogin';
import SsoLoginPage from './sso/SsoLoginPage';

function LoginPage({ loginType = 'default' }: any) {
  const [searchParams] = useSearchParams();
  if (loginType === 'tenant' || searchParams.get('login_type') === 'tenant') {
    return <TenantLogin />;
  }
  return <SsoLoginPage />;
}

export default LoginPage;
