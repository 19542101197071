// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const initialState = { uniqueId: '', id: -1 };

const reClassificationUniqueIdSlice = createSlice({
  name: 'reClassificationUniqueId', // slice name
  initialState, // initial state
  reducers: { // reducers
    setReClassificationUniqueId(state, action: PayloadAction<{uniqueId: string, id: number}>) {
      state.uniqueId = action.payload.uniqueId;
      state.id = action.payload.id;
    },
  },
});

export const { setReClassificationUniqueId } = reClassificationUniqueIdSlice.actions;
export default reClassificationUniqueIdSlice.reducer;
