import { createSlice } from '@reduxjs/toolkit';

const initialState: any = { selectedReadOnlyInformation: null };

const readOnlyInformationSlice = createSlice({
  name: 'readOnlyInformation',
  initialState,
  reducers: {
    setReadOnlyInformation: (state, action) => {
      state.selectedReadOnlyInformation = action.payload;
    },
  },
});

export const { setReadOnlyInformation } = readOnlyInformationSlice.actions;

export default readOnlyInformationSlice.reducer;
