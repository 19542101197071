import { createApi } from '@reduxjs/toolkit/dist/query/react';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import {
  IAPIRbicDashboardResponse,
  IAPIRepairDesignDashboardResponse,
  IAPIRepairDesignISODashboardResponse,
  IAPIRepairEquipmentTypeDashboardResponse,
  IAPIRepairExceedMOCDashboardResponse,
  IAPIRepairTypeDashboardResponse,
  IAPIStsDashboardResponse,
} from './dashboard-api.type';

export const dashboardApiSlice = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(build) {
    return {
      getRbicDashboardData: build.query<IAPIRbicDashboardResponse, number | void>({
        query: (companyId) => ({
          url: configs.endpoints?.dashboardRbic,
          params: { companyId },
        }),
      }),
      getRepairDesignDashboardData: build.query<IAPIRepairDesignDashboardResponse, number | void>({
        query: (companyId) => ({
          url: configs.endpoints?.dashboardRepairDesign,
          params: { companyId },
        }),
      }),
      getStsDashboardData: build.query<IAPIStsDashboardResponse, number | void>({
        query: (companyId) => ({
          url: configs.endpoints?.dashboardSts,
          params: { companyId },
        }),
      }),
      getRepairTypeDashboardData: build.query<IAPIRepairTypeDashboardResponse, number | void>({
        query: (companyId) => ({
          url: configs.endpoints?.dashboardRepairType,
          params: { companyId },
        }),
      }),
      getRepairDesignISODashboardData: build.query<IAPIRepairDesignISODashboardResponse, number | void>({
        query: (companyId) => ({
          url: configs.endpoints?.dashboardRepairDesignIso,
          params: { companyId },
        }),
      }),
      getRepairEquipmentTypeDashboardData: build.query<IAPIRepairEquipmentTypeDashboardResponse, number | void>({
        query: (companyId) => ({
          url: configs.endpoints?.dashboardEquipmentType,
          params: { companyId },
        }),
      }),
      getRepairExceedMOCDashboardData: build.query<IAPIRepairExceedMOCDashboardResponse, number | void>({
        query: (companyId) => ({
          url: configs.endpoints?.dashboardRepairExceedMOC,
          params: { companyId },
        }),
      }),
    };
  },
});

export const {
  useGetRbicDashboardDataQuery,
  useGetRepairDesignDashboardDataQuery,
  useGetStsDashboardDataQuery,
  useGetRepairTypeDashboardDataQuery,
  useGetRepairDesignISODashboardDataQuery,
  useGetRepairEquipmentTypeDashboardDataQuery,
  useGetRepairExceedMOCDashboardDataQuery,
} = dashboardApiSlice;
