// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DOCUMENTATION_FORM_INPUTS } from './types';
import { DocumentAvailabilityInterface, DocumentAvailabilityTitlePayloadAction } from '../re-classification/document-availability';

const initialState: DocumentAvailabilityInterface = {
  inputFields: {
    [DOCUMENTATION_FORM_INPUTS.DESIGN_REPORT]: '',
    [DOCUMENTATION_FORM_INPUTS.INSTALLATION_REPORT]: '',
    [DOCUMENTATION_FORM_INPUTS.POST_INSTALLATION_INSPECTION_REPORT]: '',
    [DOCUMENTATION_FORM_INPUTS.PRODUCT_QUALIFICATION_REPORT]: '',
    [DOCUMENTATION_FORM_INPUTS.SUBSTRATE_INSPECTION_REPORT]: '',
    [DOCUMENTATION_FORM_INPUTS.COMPOSITE_REPAIR_DESIGN_DATASHEET]: '',
    [DOCUMENTATION_FORM_INPUTS.COMPOSITE_CLOSEOUT_REPORT]: '',
    [DOCUMENTATION_FORM_INPUTS.SURFACE_PREPARATION_RECORD]: '',
    [DOCUMENTATION_FORM_INPUTS.QA_QC_REPORT]: '',
    [DOCUMENTATION_FORM_INPUTS.INTEGRITY_MANAGEMENT_PLAN]: '',
    [DOCUMENTATION_FORM_INPUTS.DRAWING_REPORT]: '',
  },
  fileFields: {},
};

const documentationSlice = createSlice({
  name: 'documentation', // slice name
  initialState, // initial state
  reducers: { // reducers
    updateDocumentationInputs(state, action: PayloadAction<DocumentAvailabilityTitlePayloadAction>) {
      state.inputFields[action.payload.key] = action.payload.value;
    },
  },
});

export const { updateDocumentationInputs } = documentationSlice.actions;
export default documentationSlice.reducer;
