import { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import handleLogin from '../../../utils/auth/handleLogin';
import loginLogo from '../../../assets/login-logo.png';
import { loginRequest } from '../../../app/authConfig';
import { setAccessToken, verifyTokenExpired } from '../../../utils/auth/auth.util';
import './SsoLoginPage.less';
import PnSpinner from '../../pn-spinner/PnSpinner';
import { version } from '../../../utils/version.util';

function SsoLoginPage() {
  const {
    instance,
    accounts,
  } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isExpired = verifyTokenExpired();
  const location = useLocation();

  useEffect(() => {
    // acquiring an access token
    console.log('>>>location.pathname2:', location.pathname, isExpired);
    if (
      (isAuthenticated && accounts.length > 0 && isExpired)
      // || (isAuthenticated && isTokenExpired)
    ) {
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts?.[0],
      }).then((response) => {
        // TODO: graph api for the profile data later while working with the User Profile
        // callMsGraph(response.accessToken).then((resGrapData) => {
        //   setGraphData(resGrapData);
        // });
        setAccessToken(response.accessToken);
      }).then((response) => {
        window.location.href = (location?.pathname && location.pathname.startsWith('/login')) ? '/' : location.pathname;
      });
    } else if (isAuthenticated && accounts.length > 0 && isExpired === false) {
      window.location.href = (location?.pathname && location.pathname.startsWith('/login')) ? '/' : location.pathname;
    }
  }, [
    instance,
    isAuthenticated,
    accounts,
  ]);

  if (

    // !(isAuthenticated && accounts.length > 0 && isExpired)
    (!isAuthenticated && accounts.length <= 0)
    || (isAuthenticated && isExpired)

  ) {
    return (
      <div id="login-landing">
        <div className="container">
          <div className="overlay-container">
            <div className="overlay-container-left">
              <img alt="Logo" className="logo" width="300" src={loginLogo} />
              <button type="button" onClick={() => handleLogin('redirect', instance)} className="login-button">Azure AD Login</button>
            </div>
            <div className="overlay-container-right">
              <h1 className="project-title">CoRIMS DS</h1>
              <div className="terms-area">
                {/* The use of 'http' protocol is intentional for this specific URL */}
                <a href="https://www.petronas.com" target="_blank" rel="noreferrer">Privacy Policy</a>
                {' '}
                |
                {' '}
                {/* The use of 'http' protocol is intentional for this specific URL */}
                <a href="https://www.petronas.com" target="_blank" rel="noreferrer">
                  Terms
                  &amp; Conditions

                </a>
              </div>
              <div className="version-area">
                <h4>Production</h4>
                <h5>
                  Version
                  {' '}
                  {version.current}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <PnSpinner />;
}

export default SsoLoginPage;
