// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { DEFECT_INFORMATION_FORM_INPUTS } from '../../../../form-constants/DefectInformation';
import {
  DefectInformationInterface, IDefectInfoPayload, PayloadActionType,
} from './types';
import configs from '../../../../app/configs';
import rtkFetchWrapper from '../../../../utils/rtkFetchWrapper';

const initialState: DefectInformationInterface = {
  [DEFECT_INFORMATION_FORM_INPUTS.TYPE_OF_DEFECT]: '',
  [DEFECT_INFORMATION_FORM_INPUTS.DIAMETER_OF_THROUGH_WALL_DEFECT]: '',
  [DEFECT_INFORMATION_FORM_INPUTS.DIAMETER_OF_THROUGH_WALL_DEFECT_UNIT]: 'mm',
  [DEFECT_INFORMATION_FORM_INPUTS.AXIAL_LENGTH_OF_DEFECT]: '',
  [DEFECT_INFORMATION_FORM_INPUTS.AXIAL_LENGTH_OF_DEFECT_UNIT]: 'mm',
  [DEFECT_INFORMATION_FORM_INPUTS.MAWP_PSAFE]: '',
  [DEFECT_INFORMATION_FORM_INPUTS.MAWP_PSAFE_UNIT]: 'Bar',
  [DEFECT_INFORMATION_FORM_INPUTS.SHAPE_OF_DEFECT]: '',
  [DEFECT_INFORMATION_FORM_INPUTS.MINIMUM_REMAINING_SUBSTRATE_WALL_THICKNESS]: '',
  [DEFECT_INFORMATION_FORM_INPUTS.MINIMUM_REMAINING_SUBSTRATE_WALL_THICKNESS_UNIT]: 'mm',
  [DEFECT_INFORMATION_FORM_INPUTS.AXIAL_CIRCUMFERENTIAL_SLOT]: '',
  [DEFECT_INFORMATION_FORM_INPUTS.AXIAL_CIRCUMFERENTIAL_SLOT_UNIT]: 'mm',
};

const defectInformationSlice = createSlice({
  name: 'defectInformation', // slice name
  initialState, // initial state
  reducers: { // reducers
    setDefectInformationValue(state: DefectInformationInterface, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const defectInfoSlice = createApi({
  reducerPath: 'defectInfoApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addDefectInfo: builder.mutation({
        query: (payload: IDefectInfoPayload) => ({
          url: configs?.endpoints?.defectInfo,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { setDefectInformationValue } = defectInformationSlice.actions;
export default defectInformationSlice.reducer;
export const { useAddDefectInfoMutation } = defectInfoSlice;
