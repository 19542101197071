export enum POST_APPLICATION {
  EXCEED_REPAIR_DESIGN_LIFE_TIME = 'exceedRepairDesignLifetime',
  COMPLIANCE_TO_IMP = 'complianceToIMP',
  DEFECTS = 'defects',
}

export enum POST_APPLICATION_DEFECTS {
  PRESENCE_OF_DEFECTS = 'presenceOfDefect',
  SEVERITY = 'severity'
}

export interface PostApplicationDefectInterface {
  [POST_APPLICATION_DEFECTS.PRESENCE_OF_DEFECTS]: string;
  [POST_APPLICATION_DEFECTS.SEVERITY]: string;
}

export interface PostApplicationInterface {
  [POST_APPLICATION.EXCEED_REPAIR_DESIGN_LIFE_TIME]: string;
  [POST_APPLICATION.COMPLIANCE_TO_IMP]: string;
  [POST_APPLICATION.DEFECTS]: PostApplicationDefectInterface[];
}

export interface PostApplicationPayloadAction {
  type: POST_APPLICATION.EXCEED_REPAIR_DESIGN_LIFE_TIME | POST_APPLICATION.COMPLIANCE_TO_IMP;
  payload: string;
}

export interface PostApplicationDefectPayloadAction {
  index: number;
  key: POST_APPLICATION_DEFECTS.PRESENCE_OF_DEFECTS | POST_APPLICATION_DEFECTS.SEVERITY;
  payload: string;
}
