// DUCKS pattern
import { createApi } from '@reduxjs/toolkit/query/react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DESIGN_SPECIFICATION_FORM_INPUTS } from '../../../../form-constants/DesignSpecification';
import {
  DesignSpecificationInterface, IDesignSpecPayload, PayloadActionType,
} from './types';
import configs from '../../../../app/configs';
import rtkFetchWrapper from '../../../../utils/rtkFetchWrapper';

const initialState: DesignSpecificationInterface = {
  [DESIGN_SPECIFICATION_FORM_INPUTS.DESIGN_INTERNAL_PRESSURE]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.DESIGN_INTERNAL_PRESSURE_UNIT]: 'Bar',
  [DESIGN_SPECIFICATION_FORM_INPUTS.INTERNAL_PRESSURE_DURING_APPLICATION_OF_REPAIR]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.INTERNAL_PRESSURE_DURING_APPLICATION_OF_REPAIR_UNIT]: 'Bar',
  [DESIGN_SPECIFICATION_FORM_INPUTS.INTERNAL_PRESSURE_AFTER_APPLICATION_OF_REPAIR]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.INTERNAL_PRESSURE_AFTER_APPLICATION_OF_REPAIR_UNIT]: 'Bar',
  [DESIGN_SPECIFICATION_FORM_INPUTS.TEMPERATURE_DURING_APPLICATION_OF_REPAIR]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.TEMPERATURE_AFTER_APPLICATION_OF_REPAIR]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.APPLIED_AXIAL_LOAD]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.APPLIED_SHEAR_LOAD]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.APPLIED_SHEAR_MOMENT]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.APPLIED_TORSIONAL_MOMENT]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.DESIGN_TEMPERATURE]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.INSTALLATION_TEMPERATURE]: '',
  [DESIGN_SPECIFICATION_FORM_INPUTS.CORROSION_PROTECTION_REQUIREMENT]: 'No',
};

const designSpecificationSlice = createSlice({
  name: 'designSpecification', // slice name
  initialState, // initial state
  reducers: { // reducers
    setDesignSpecificationValue(state: DesignSpecificationInterface, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const designSpecSlice = createApi({
  reducerPath: 'designSpecApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addDefectInfo: builder.mutation({
        query: (payload: IDesignSpecPayload) => ({
          url: configs?.endpoints?.designSpecs,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { setDesignSpecificationValue } = designSpecificationSlice.actions;
export default designSpecificationSlice.reducer;
export const { useAddDefectInfoMutation } = designSpecSlice;
