// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DESIGN_REVALIDATION_FORM_INPUTS,
  DesignRevalidationInterface,
  PayloadActionType,
} from './type';

export const initialState: DesignRevalidationInterface = {
  [DESIGN_REVALIDATION_FORM_INPUTS.REPORTED_IN_RBIC]: '',
  [DESIGN_REVALIDATION_FORM_INPUTS.DEFECT_ON_COMPOSITE]: '',
  [DESIGN_REVALIDATION_FORM_INPUTS.LIFETIME_EXTENSION_PERIOD]: '',
};

const DesignRevalidationSlice = createSlice({
  name: 'DesignRevalidation', // slice name
  initialState, // initial state
  reducers: { // reducers
    setDesignRevalidationValue(state: DesignRevalidationInterface, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const { setDesignRevalidationValue } = DesignRevalidationSlice.actions;
export default DesignRevalidationSlice.reducer;
