import { createApi } from '@reduxjs/toolkit/query/react';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import configs from '../../app/configs';
import {
  IAPIRLTEAssesmentDropdownResponse, RLTEAssesmentQueryParams, RLTEAssesmentSearchResponse,
} from './types';

export const lifetimeExtensionApiSlice = createApi({
  reducerPath: 'lifetimeExtensionApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addDoc: builder.mutation({
        query: (payload: FormData) => ({
          url: configs.endpoints?.addDoc,
          method: 'POST',
          body: payload,
        }),
      }),
      getDropdownData: builder.query<IAPIRLTEAssesmentDropdownResponse, void>({ query: () => ({ url: configs.endpoints.rlteAssessmentDropdown }) }),
      getAllRLTEAssessments: builder.query<RLTEAssesmentSearchResponse, RLTEAssesmentQueryParams | void>({
        query(queryParams) {
          const options: any = { url: configs?.endpoints?.rlteAssessments };

          if (queryParams) {
            options.params = queryParams;
          }

          return options;
        },
      }),
    };
  },
});

export const {
  useAddDocMutation,
  useGetDropdownDataQuery,
  useGetAllRLTEAssessmentsQuery,
} = lifetimeExtensionApiSlice;
