import { createApi } from '@reduxjs/toolkit/query/react';
import configs from '../../../app/configs';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';

export const rlteAssessmentSlice = createApi({
  reducerPath: 'rlteAssessmentApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addAssessment: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.rlteAssessments,
          method: 'POST',
          body: payload,
        }),
      }),
      updateAssessment: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.rlteAssessments,
          method: 'PATCH',
          body: payload,
        }),
      }),
      getRLTEById: builder.query<any, string>({
        query(lifeTimeId: string) {
          return { url: `${configs?.endpoints?.rlteAssessments}/${lifeTimeId}` };
        },
      }),
    };
  },
});

export const {
  useAddAssessmentMutation,
  useGetRLTEByIdQuery,
  useUpdateAssessmentMutation,
} = rlteAssessmentSlice;
