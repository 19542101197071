// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SubmissionAndApproval } from '.';

const initialState: SubmissionAndApproval = {
  submitterName: '',
  submitterEmail: '',
  reviewerName: '',
  reviewerEmail: '',
  reviewRemark: '',
  approvalRemark: '',
  moduleId: 'RBIC001',
  requestType: 'RBIC',
  approverEmail: '',
  approverName: '',
  status: 'PENDING_REVIEW',
  emailTemplate: 'ADD_SUBMISSION',
};

const submissionAndApprovalSlice = createSlice({
  name: 'submissionAndApproval', // slice name
  initialState, // initial state
  reducers: { // reducers
    setSubmissionAndApprovalValue(state: SubmissionAndApproval, action: PayloadAction<{key: keyof SubmissionAndApproval, value: string}>) {
      state[action.payload.key] = action.payload.value;
    },
    setSubmissionAndApprovalState(state: SubmissionAndApproval, action: PayloadAction<SubmissionAndApproval>) {
      state.submitterName = action.payload.submitterName;
      state.submitterEmail = action.payload.submitterEmail;
      state.reviewerName = action.payload.reviewerName;
      state.reviewerEmail = action.payload.reviewerEmail;
      state.reviewRemark = action.payload.reviewRemark;
      state.approvalRemark = action.payload.approvalRemark;
      state.moduleId = action.payload.moduleId;
      state.requestType = action.payload.requestType;
      state.approverEmail = action.payload.approverEmail;
      state.approverName = action.payload.approverName;
      state.status = action.payload.status;
      state.emailTemplate = action.payload.emailTemplate;
    },
  },
});

export const { setSubmissionAndApprovalValue, setSubmissionAndApprovalState } = submissionAndApprovalSlice.actions;
export default submissionAndApprovalSlice.reducer;
