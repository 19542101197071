import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AddAssetOpertationType,
  AddAssetState,
  IAssetData,
} from './types';

const initialState: AddAssetState = {
  operation: 'default',
  selectedAssetData: null,
};

const addAssetSlice = createSlice({
  name: 'selectedAssetData',
  initialState,
  reducers: {
    setAssetDataHistoryOperation: (state, action: PayloadAction<AddAssetOpertationType>) => {
      state.operation = action.payload;
    },
    setAssetData: (state, action: PayloadAction<IAssetData>) => {
      state.selectedAssetData = action.payload;
    },
  },
});

export const {
  setAssetData,
  setAssetDataHistoryOperation,
} = addAssetSlice.actions;

export default addAssetSlice.reducer;
