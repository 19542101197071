// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  PayloadActionType,
  RISK_ASSESSMENT_FORM_INPUTS,
  RiskAssessmentInterface,
} from './types';

export const initialState: RiskAssessmentInterface = {
  [RISK_ASSESSMENT_FORM_INPUTS.IS_FILE_UPLOADED]: '',
  [RISK_ASSESSMENT_FORM_INPUTS.RISK]: '',
  [RISK_ASSESSMENT_FORM_INPUTS.IPSS_RISK_RATING]: '',
  [RISK_ASSESSMENT_FORM_INPUTS.CORIMS_RBIC_SUMMARY]: '',
  [RISK_ASSESSMENT_FORM_INPUTS.FIELD_RISK_ASSESSMENT_REPORT]: null,
};

const RiskAssessmentSlice = createSlice({
  name: 'RiskAssessment', // slice name
  initialState, // initial state
  reducers: { // reducers
    setRiskAssessmentValue(state: RiskAssessmentInterface | any, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const { setRiskAssessmentValue } = RiskAssessmentSlice.actions;
export default RiskAssessmentSlice.reducer;
