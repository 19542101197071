import { createApi } from '@reduxjs/toolkit/query/react';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import {
  IAssetAddResponse,
  IAssetDataBulkResponse,
  IAssetDataDropdownResponse,
  IAssetDataUpdate,
  IAssetUpdateResponse,
  IGetAllAssetDataById,
  IGetAllAssetDataQueryParams,
  IGetAllAssetDataResponse,
} from './assetData-api.types';
import { IAsset } from '../Dtos/IAssetDto';

const default_page_size = 50;
const page_index = 0;

export const compositeRepairApiSlice = createApi({
  reducerPath: 'compositeRepairApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addCompositeReapir: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.compositeRepairData,
          method: 'POST',
          body: payload,
        }),
      }),
      filterCompositeReapir: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.compositeRepairDataFilter,
          method: 'POST',
          body: payload,
        }),
      }),
      filterAssetData: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.assetDataFilter,
          method: 'POST',
          body: payload,
        }),
      }),
      filterAssetDataWithRepair: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.assetDataWithRepairFilter,
          method: 'POST',
          body: payload,
        }),
      }),
      filterRepairDesignData: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.repairDesignDataFilter,
          method: 'POST',
          body: payload,
        }),
      }),
      getipimsCompanyList: builder.query({
        query: () => ({
          url: configs?.endpoints?.ipimsCompaniesList,
          method: 'GET',
          headers: {
            iPIMSToken: `Bearer ${localStorage.iPIMSToken}`,
            clientId: process.env.REACT_APP_IPIMS_CLIENT_ID,
            clientSecret: process.env.REACT_APP_IPIMS_CLIENT_SECRET,
          },
        }),
      }),
      getissureCompanyList: builder.query({
        query: () => ({
          url: configs?.endpoints?.iassureCompanyList,
          method: 'GET',
          headers: {
            iASSUREToken: `Basic ${process.env.REACT_APP_IASSURE_AUTHORIZATION}`,
            clientId: process.env.REACT_APP_IASSURE_CLIENT_ID,
            clientSecret: process.env.REACT_APP_IASSURE_CLIENT_SECRET,
          },
        }),
      }),
      getipimsPipelineList: builder.mutation({
        query: (companyCode) => ({
          url: configs?.endpoints?.ipimsPipelineList,
          method: 'GET',
          params: {
            RegionCode: companyCode, PageIndex: page_index, PageSize: default_page_size,
          },
          headers: {
            iPIMSToken: `Bearer ${localStorage.iPIMSToken}`,
            clientId: process.env.REACT_APP_IPIMS_CLIENT_ID,
            clientSecret: process.env.REACT_APP_IPIMS_CLIENT_SECRET,
          },
        }),
      }),
      getipimsCompositeRepairList: builder.query({
        query: (pipelineId) => ({
          url: configs?.endpoints?.ipimsCompositeRepairList,
          method: 'GET',
          params: { PipelineId: pipelineId },
          headers: {
            iPIMSToken: `Bearer ${localStorage.iPIMSToken}`,
            clientId: process.env.REACT_APP_IPIMS_CLIENT_ID,
            clientSecret: process.env.REACT_APP_IPIMS_CLIENT_SECRET,
          },
        }),
      }),
      getIPimsCombinedData: builder.mutation({
        query: (PipelineId) => ({
          url: configs?.endpoints?.ipimsCombinedData,
          method: 'GET',
          params: {
            PipelineId, PageIndex: page_index, PageSize: default_page_size,
          },
          headers: {
            iPIMSToken: `Bearer ${localStorage.iPIMSToken}`,
            clientId: process.env.REACT_APP_IPIMS_CLIENT_ID,
            clientSecret: process.env.REACT_APP_IPIMS_CLIENT_SECRET,
          },
        }),
      }),
      getipimsAccessToken: builder.query({
        query: () => ({
          url: configs?.endpoints?.ipimsAccessToken,
          method: 'POST',
          body: { applicationId: process.env.REACT_APP_IPIMS_APP_ID },
          headers: {
            clientId: process.env.REACT_APP_IPIMS_CLIENT_ID,
            clientSecret: process.env.REACT_APP_IPIMS_CLIENT_SECRET,
          },
        }),
      }),
      getcomponentdetails: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.iassureComponentDetails,
          method: 'POST',
          body: payload,
          headers: {
            iASSUREToken: `Basic ${process.env.REACT_APP_IASSURE_AUTHORIZATION}`,
            clientId: process.env.REACT_APP_IASSURE_CLIENT_ID,
            clientSecret: process.env.REACT_APP_IASSURE_CLIENT_SECRET,
          },
        }),
      }),
      postAssetData: builder.mutation<IAssetAddResponse, IAsset>({
        query: (payload) => ({
          url: `${configs?.endpoints?.assets}`,
          method: 'POST',
          body: payload,
        }),
      }),
      postAssetDataBulk: builder.mutation<IAssetDataBulkResponse, IAsset>({
        query: (payload) => ({
          url: `${configs?.endpoints?.assetBulk}`,
          method: 'POST',
          body: payload,
        }),
      }),
      updateAssetData: builder.mutation<IAssetUpdateResponse, IAssetDataUpdate>({
        query: (payload) => ({
          url: `${configs?.endpoints?.assets}`,
          method: 'PATCH',
          body: payload,
        }),
      }),
      getAllAssetData: builder.query<IGetAllAssetDataResponse, IGetAllAssetDataQueryParams | void>({
        query(queryParams) {
          const options: any = { url: configs?.endpoints.assets };

          if (queryParams) {
            options.params = queryParams;
          }

          return options;
        },
      }),
      getAllAssetDataById: builder.query<IGetAllAssetDataById, string>({
        query(assetDataId: string) {
          return { url: `${configs?.endpoints?.assets}/${assetDataId}` };
        },
      }),
      getAssetDataDropDown: builder.query<IAssetDataDropdownResponse, void>({ query: () => ({ url: configs?.endpoints.assetDropDown }) }),
      filterMappingFromCoR: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.CoRsToRbicMapping,
          params: { corsId: payload },
          method: 'GET',
        }),
      }),
      getCoRDataForIntegrity: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.integrityMapping,
          params: { repairDesignCode: payload },
          method: 'GET',
        }),
      }),
      postNRRBulk: builder.mutation<any, any>({
        query: (payload) => ({
          url: `${configs?.endpoints?.compositeRepairDataBulk}`,
          method: 'POST',
          body: payload,
        }),
      }),
      getRepairLifetimeExtensionPeriodById: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.repairLifetimeExtensionPeriod,
          params: { repairDesignCode: payload },
          method: 'GET',
        }),
      }),
    };
  },
});

export const {
  useAddCompositeReapirMutation,
  useFilterCompositeReapirMutation,
  useGetAllAssetDataQuery,
  useGetAssetDataDropDownQuery,
  usePostAssetDataMutation,
  usePostAssetDataBulkMutation,
  useUpdateAssetDataMutation,
  useGetAllAssetDataByIdQuery,
  useFilterAssetDataMutation,
  useFilterRepairDesignDataMutation,
  useFilterAssetDataWithRepairMutation,
  useGetipimsCompanyListQuery,
  useGetipimsAccessTokenQuery,
  useGetipimsPipelineListMutation,
  useGetipimsCompositeRepairListQuery,
  useGetissureCompanyListQuery,
  useGetcomponentdetailsMutation,
  useGetIPimsCombinedDataMutation,
  useFilterMappingFromCoRMutation,
  useGetCoRDataForIntegrityMutation,
  usePostNRRBulkMutation,
  useGetRepairLifetimeExtensionPeriodByIdMutation,
} = compositeRepairApiSlice;
