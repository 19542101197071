// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  USERS_FORM_INPUTS,
  UserInterface,
} from './types';

const initialState: UserInterface = {
  [USERS_FORM_INPUTS.USER_NAME]: '',
  [USERS_FORM_INPUTS.USER_EMAIL]: '',
  [USERS_FORM_INPUTS.USER_ROLE]: '',
  [USERS_FORM_INPUTS.USER_ROLE_ID]: '',
  [USERS_FORM_INPUTS.USER_COMPANY]: '',
  [USERS_FORM_INPUTS.USER_COMPANY_ID]: null,
  [USERS_FORM_INPUTS.USER_STATUS]: '',
  [USERS_FORM_INPUTS.USER_CREATED_BY]: '',
  [USERS_FORM_INPUTS.USER_ID]: '',
};

const userSlice = createSlice({
  name: 'userRole', // slice name
  initialState, // initial state
  reducers: { // reducers
    setUserValue(state: UserInterface, action: PayloadAction<UserInterface>) {
      state[USERS_FORM_INPUTS.USER_NAME] = action.payload[USERS_FORM_INPUTS.USER_NAME];
      state[USERS_FORM_INPUTS.USER_EMAIL] = action.payload[USERS_FORM_INPUTS.USER_EMAIL];
      state[USERS_FORM_INPUTS.USER_ROLE] = action.payload[USERS_FORM_INPUTS.USER_ROLE];
      state[USERS_FORM_INPUTS.USER_ROLE_ID] = action.payload[USERS_FORM_INPUTS.USER_ROLE_ID];
      state[USERS_FORM_INPUTS.USER_COMPANY] = action.payload[USERS_FORM_INPUTS.USER_COMPANY];
      state[USERS_FORM_INPUTS.USER_COMPANY_ID] = action.payload[USERS_FORM_INPUTS.USER_COMPANY_ID];
      state[USERS_FORM_INPUTS.USER_STATUS] = action.payload[USERS_FORM_INPUTS.USER_STATUS];
      state[USERS_FORM_INPUTS.USER_CREATED_BY] = action.payload[USERS_FORM_INPUTS.USER_CREATED_BY];
      state[USERS_FORM_INPUTS.USER_ID] = action.payload[USERS_FORM_INPUTS.USER_ID];
    },
  },
});

export const { setUserValue } = userSlice.actions;
export default userSlice.reducer;
