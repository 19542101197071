// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ODD_GEOMETRY_SPECIFICATION_FORM_INPUTS } from '../../../../form-constants/OddGeometrySpecification';
import {
  IOddGeometrySpecificationPayload, OddGeometrySpecificationInterface, PayloadActionType,
} from './types';
import configs from '../../../../app/configs';
import rtkFetchWrapper from '../../../../utils/rtkFetchWrapper';

const initialState: OddGeometrySpecificationInterface = {
  [ODD_GEOMETRY_SPECIFICATION_FORM_INPUTS.SELECT_ODD_GEOMETRY_SPECIFICATION]: '',
  [ODD_GEOMETRY_SPECIFICATION_FORM_INPUTS.SELECT_PIPING_SYSTEM_COMPONENT]: '',
  [ODD_GEOMETRY_SPECIFICATION_FORM_INPUTS.SELECT_CYLINDRICAL_VESSEL_COMPONENT]: '',
  [ODD_GEOMETRY_SPECIFICATION_FORM_INPUTS.SELECT_SPHERICAL_VESSEL_COMPONENT]: '',
};

const oddGeometrySpecificationSlice = createSlice({
  name: 'oddGeometrySpecification', // slice name
  initialState, // initial state
  reducers: { // reducers
    setOddGeometrySpecificationValue(state: OddGeometrySpecificationInterface, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = action.payload.payload;
    },
  },
});

export const oddGeometrySlice = createApi({
  reducerPath: 'oddGeometryApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addOddGeometry: builder.mutation({
        query: (payload: IOddGeometrySpecificationPayload) => ({
          url: configs?.endpoints?.oddGeometries,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { setOddGeometrySpecificationValue } = oddGeometrySpecificationSlice.actions;
export default oddGeometrySpecificationSlice.reducer;
export const { useAddOddGeometryMutation } = oddGeometrySlice;
